import posthog from 'posthog-js';
import getConfig from 'next/config';

const { publicRuntimeConfig: config } = getConfig();
const defaultOptions = {};

export const POSTHOG_TRACK_EVENTS = {
  USER_INPUT: 'USER_INPUT',
  PAGE_VIEW: 'PAGE_VIEW',
  USER_FAIL_AVS: 'USER_FAIL_AVS',
  USER_VERIFICATION_LEVEL_ONE_REQ: 'USER_VERIFICATION_LEVEL_ONE_REQ',
  USER_VERIFICATION_LEVEL_TWO_REQ: 'USER_VERIFICATION_LEVEL_TWO_REQ',
  USER_VERIFICATION_LEVEL_ONE_PASS: 'USER_VERIFICATION_LEVEL_ONE_PASS',
  USER_VERIFICATION_LEVEL_ONE_FAIL: 'USER_VERIFICATION_LEVEL_ONE_FAIL',
  USER_VERIFICATION_LEVEL_TWO_CANCEL: 'USER_VERIFICATION_LEVEL_TWO_CANCEL',
  USER_VERIFICATION_LEVEL_TWO_PASS: 'USER_VERIFICATION_LEVEL_TWO_PASS',
  USER_VERIFICATION_LEVEL_TWO_FAIL: 'USER_VERIFICATION_LEVEL_TWO_FAIL',
  USER_SEND_AMOUNT_UPDATE: 'USER_SEND_AMOUNT_UPDATE',
  USER_EMAIL_UPDATE: 'USER_EMAIL_UPDATE',
  USER_PHONE_UPDATE: 'USER_PHONE_UPDATE',
  USER_FIRST_NAME_UPDATE: 'USER_FIRST_NAME_UPDATE',
  USER_LAST_NAME_UPDATE: 'USER_LAST_NAME_UPDATE',
  USER_ADDRESS_ONE_UPDATE: 'USER_ADDRESS_ONE_UPDATE',
  USER_ADDRESS_TWO_UPDATE: 'USER_ADDRESS_TWO_UPDATE',
  USER_CITY_UPDATE: 'USER_CITY_UPDATE',
  USER_STATE_UPDATE: 'USER_STATE_UPDATE',
  USER_POSTAL_UPDATE: 'USER_POSTAL_UPDATE',
  USER_DOB_MONTH_UPDATE: 'USER_DOB_MONTH_UPDATE',
  USER_DOB_DAY_UPDATE: 'USER_DOB_DAY_UPDATE',
  USER_DOB_YEAR_UPDATE: 'USER_DOB_YEAR_UPDATE',
  SERVICE_GET_FX_RATE: 'SERVICE_GET_FX_RATE',
  SERVICE_GET_LINK_PROFILE: 'SERVICE_GET_LINK_PROFILE',
  SERVICE_AUTH_EMAIL: 'SERVICE_AUTH_EMAIL',
  SERVICE_PHONE_VER_CODE: 'SERVICE_PHONE_VER_CODE',
  SERVICE_EMAIL_VER_CODE: 'SERVICE_EMAIL_VER_CODE',
  SERVICE_VERIFY_PHONE: 'SERVICE_VERIFY_PHONE',
  SERVICE_VERIFY_EMAIL: 'SERVICE_VERIFY_EMAIL',
  SERVICE_CREATE_IDENTITY: 'SERVICE_CREATE_IDENTITY',
  SERVICE_CREATE_ACCOUNT_CHALLENGE: 'SERVICE_CREATE_ACCOUNT_CHALLENGE',
  SERVICE_VERIFY_ACCOUNT_CHALLENGE: 'SERVICE_VERIFY_ACCOUNT_CHALLENGE',
  SERVICE_VERIFY_IDENTITY_LEVEL_ONE: 'SERVICE_VERIFY_IDENTITY_LEVEL_ONE',
  SERVICE_VERIFY_IDENTITY_LEVEL_TWO: 'SERVICE_VERIFY_IDENTITY_LEVEL_TWO',
  SERVICE_CREATE_PAYMENT_METHOD: 'SERVICE_CREATE_PAYMENT_METHOD',
  SERVICE_GET_PAYMENT_METHODS: 'SERVICE_GET_PAYMENT_METHODS',
  SERVICE_CREATE_TRANSFER: 'SERVICE_CREATE_TRANSFER',
  SERVICE_UPDATE_TRANSFER_EXCHANGE: 'SERVICE_UPDATE_TRANSFER_EXCHANGE',
  SERVICE_GET_ACCOUNT: 'SERVICE_GET_ACCOUNT',
  SERVICE_CREATE_ACCOUNT: 'SERVICE_CREATE_ACCOUNT',
  SERVICE_GET_TRANSFER: 'SERVICE_GET_TRANSFER',
  SERVICE_GET_LIMITS: 'SERVICE_GET_LIMITS',
  SERVICE_GET_PUBLIC_LIMITS: 'SERVICE_GET_PUBLIC_LIMITS',
  SERVICE_GET_RELATIONSHIP_BY_LINK_ID: 'SERVICE_GET_RELATIONSHIP_BY_LINK_ID',
  SERVICE_GET_RELATIONSHIP_BY_ID: 'SERVICE_GET_RELATIONSHIP_BY_ID',
  SERVICE_CLEAR_PAYMENT_METHODS: 'SERVICE_CLEAR_PAYMENT_METHODS',
  SERVICE_IDENTITY_LEVEL_ONE: 'SERVICE_IDENTITY_LEVEL_ONE',
  SERVICE_IDENTITY_LEVEL_TWO: 'SERVICE_IDENTITY_LEVEL_TWO',
  SERVICE_IDENTITY_TOKEN_EXCHANGE: 'SERVICE_IDENTITY_TOKEN_EXCHANGE',
  SERVICE_GET_IDENTITY_VERIFICATIONS: 'SERVICE_GET_IDENTITY_VERIFICATIONS',
  SERVICE_DELETE_PAYMENT_METHOD: 'SERVICE_DELETE_PAYMENT_METHOD',
};

const init = () => {
  return posthog.init(config.posthogApiKey, {
    api_host: config.posthogApiUrl,
    autocapture: config.posthogAutocapture,
  });
};

const send = (event, data, options) => {
  if (!event) return;
  return posthog.capture(event.toLowerCase(), data, {
    ...defaultOptions,
    ...(options || {}),
  });
};

const setUser = ({ userId, ...userData }) => posthog.identify(userId, userData);

const resetUser = () => posthog.reset();

export default {
  init,
  send,
  setUser,
  resetUser,
};
