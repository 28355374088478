import { assign } from 'xstate';

const updatePallaTransfer = assign({
  palla: (context, { data, payload }) => {
    const transferId = data ? data.transferId : payload.transferId;
    const transferCreated = data ? data.created : payload.created;
    return {
      ...context.palla,
      transferId,
      transferCreated,
    };
  },
});

export default updatePallaTransfer;
