import { assign } from 'xstate';

const updateAccount = assign({
  account: ({ account }, { payload }) => {
    return {
      ...account,
      ...payload.account,
    };
  },
});

export default updateAccount;
