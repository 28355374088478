import { assign } from 'xstate';

const updatePreferredLang = assign({
  userTrackingData: ({ userTrackingData }, { payload }) => ({
    ...userTrackingData,
    preferredLang: payload.lang,
  }),
});

export default updatePreferredLang;
