import { assign } from 'xstate';

const updatePayment = assign({
  payment: ({ payment }, { payload }) => {
    return { ...payment, ...payload.payment };
  },
  paymentCardToken: ({ paymentCardToken }, { payload }) => {
    return Object.hasOwnProperty.call(payload, 'paymentCardToken')
      ? payload.paymentCardToken
      : paymentCardToken;
  },
});

export default updatePayment;
