import { assign } from 'xstate';

const updateChallenge = assign({
  challenge: ({ challenge }, { payload }) => ({
    ...challenge,
    ...payload.challenge,
  }),
});

export default updateChallenge;
