import { assign } from 'xstate';

const clearTransferAmount = assign({
  transfer: ({ transfer }) => ({
    ...transfer,
    inputAmount: '',
    amount: 0.0,
    exchangeAmount: 0.0,
    totalAmount: 0,
    sendMore: true,
  }),
});

export default clearTransferAmount;
