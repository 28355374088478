module.exports = {
  locales: process.env.NEXT_PUBLIC_LOCALE_OPTIONS?.split(',') || ['en'],
  defaultLocale: process.env.NEXT_PUBLIC_DEFAULT_LOCALE_OPTION || 'en',
  localeDetection: false,
  loadLocaleFrom: (locale, namespace) =>
    import(`./locales/${locale}/${namespace}`).then((m) => m.default),
  pages: {
    '*': ['index'],
  },
  interpolation: {
    prefix: '{',
    suffix: '}',
  },
  loader: false,
};
