const MAP_VALUES = {
  'https://palla.app/id/': 'userId',
  'https://palla.app/email/': 'email',
  'https://palla.app/country/': 'country',
  'https://palla.app/provider/': 'provider',
  'https://palla.app/scope/': 'scope',
  iss: 'issuer',
};

const mapJwtDataToUserData = (jwtData) => {
  if (!jwtData) return null;
  return Object.keys(jwtData).reduce((acc, key) => {
    if (!MAP_VALUES[key]) return acc;
    if (key === 'iss') {
      // reference: https://regex101.com/r/vK4rV7/1
      const [, , issuer, env] = jwtData[key].match(
        /(http[s]?:\/\/)?([^\/\s]+\/)(.*)/
      );
      return {
        ...acc,
        issuer: issuer ? issuer.slice(0, -1) : null,
        env: env ? env.slice(0, -1) : 'production',
      };
    }
    return { ...acc, [MAP_VALUES[key]]: jwtData[key] };
  }, {});
};

export default mapJwtDataToUserData;
