import constants, {
  COMMON_KEY,
  ERRORS_KEY,
  NOT_FOUND_KEY,
  ABOUT_KEY,
  DETAILS_KEY,
  VERIFICATION_KEY,
  SUMMARY_KEY,
  TRANSFER_ERROR_KEY,
  TRANSFER_SUCCESS_KEY,
  PAYMENT_KEY,
  RECEIPT_KEY,
  SUPPORT_MODAL_KEY,
  ABOUT_LIST_KEY,
  SEND_AMOUNT_KEY,
  BRAND_HEADER_KEY,
  ACCOUNT_DETAILS_KEY,
  KYC_FAIL_KEY,
  TRANSACTION_SUMMARY_KEY,
  SECURE_PAYMENT_KEY,
  PHONE_VERIFICATION_KEY,
  EMAIL_VERIFICATION_KEY,
  RECEIPT_FOOTER_KEY,
  IDV_DESKTOP_KEY,
  PERSONAL_DETAILS_KEY,
  UPDATE_PHONE_KEY,
} from '../constants';

const {
  ABOUT_DESCRIPTION,
  ABOUT_LIST_ASK,
  ABOUT_LIST_CHOOSE,
  ABOUT_LIST_INSTANT,
  ABOUT_TITLE,
  ACCOUNT_DETAILS_EMAIL_LABEL,
  ACCOUNT_DETAILS_EMAIL_PLACEHOLDER,
  ACCOUNT_DETAILS_FIRST_NAME_LABEL,
  ACCOUNT_DETAILS_FIRST_NAME_PLACEHOLDER,
  ACCOUNT_DETAILS_INT_PLACEHOLDER,
  ACCOUNT_DETAILS_INVALID_EMAIL,
  ACCOUNT_DETAILS_INVALID_FIRST_NAME,
  ACCOUNT_DETAILS_INVALID_LAST_NAME,
  ACCOUNT_DETAILS_INVALID_PHONE,
  ACCOUNT_DETAILS_LAST_NAME_LABEL,
  ACCOUNT_DETAILS_LAST_NAME_PLACEHOLDER,
  ACCOUNT_DETAILS_PHONE_LABEL,
  ACCOUNT_DETAILS_PHONE_PLACEHOLDER,
  ACCOUNT_DETAILS_PHONE_TAKEN,
  ACCOUNT_DETAILS_TERMS,
  ACCOUNT_DETAILS_TITLE,
  BRAND_HEADER_SEND_MONEY_INSTANTLY,
  BRAND_HEADER_TITLE,
  COMMON_BACK,
  COMMON_CANCEL,
  COMMON_CONTACT_US,
  COMMON_CONTINUE,
  COMMON_COPIED,
  COMMON_EDIT,
  COMMON_ERROR,
  COMMON_RETRY,
  COMMON_SEND_MORE_MONEY,
  COMMON_SEND_MORE_MONEY_CTA,
  COMMON_SERVICE_ERROR,
  COMMON_SHARE,
  COMMON_UPGRADE_ACCOUNT,
  DETAILS_ACCOUNT,
  DETAILS_SEND_AMOUNT,
  DETAILS_TITLE,
  EMAIL_VERIFICATION_NEW_CODE,
  EMAIL_VERIFICATION_NEW_CODE_HEADER,
  EMAIL_VERIFICATION_SENT_TO_HEADER,
  EMAIL_VERIFICATION_SUB_HEADER,
  ERRORS_ACCOUNT_INVALID_COUNTRY,
  ERRORS_ACCOUNT_TITLE,
  ERRORS_ACCOUNT_US_ADDRESS,
  ERRORS_ACCOUNT_US_DEBIT,
  ERRORS_ACCOUNT_US_PHONE,
  ERRORS_ACCOUNT_WHITELIST_COUNTRY_PROCEED,
  ERRORS_BLOCKED_BUTTON,
  ERRORS_BLOCKED_DESCRIPTION,
  ERRORS_BLOCKED_TITLE,
  ERRORS_GENERIC_BUTTON,
  ERRORS_GENERIC_DESCRIPTION,
  ERRORS_GENERIC_TITLE,
  ERRORS_IDV_UPGRADE_FAIL,
  ERRORS_INVALID_LINK_BUTTON,
  ERRORS_INVALID_LINK_DESCRIPTION,
  ERRORS_INVALID_LINK_TITLE,
  ERRORS_KYC_FAIL_BUTTON,
  ERRORS_KYC_FAIL_DESCRIPTION,
  ERRORS_KYC_FAIL_TITLE,
  ERRORS_PAYMENT_AMOUNT_LIMIT,
  ERRORS_PAYMENT_CREDIT_CARD,
  ERRORS_PAYMENT_DUPLICATE,
  ERRORS_PAYMENT_FREQUENCY_LIMIT,
  ERRORS_PAYMENT_INVALID,
  ERRORS_PAYMENT_INVALID_ADDRESS,
  ERRORS_PAYMENT_INVALID_CONFIG,
  ERRORS_PAYMENT_INVALID_DETAILS,
  ERRORS_PAYMENT_INVALID_DETAILS_ADDRESS,
  ERRORS_PAYMENT_INVALID_PAYMENT_DETAILS,
  ERRORS_PAYMENT_INVALID_PAYMENT_METHOD_CURRENCY,
  ERRORS_PAYMENT_INVALID_PAYMENT_METHOD_DETAILS,
  ERRORS_PAYMENT_INVALID_PAYMENT_METHOD_OFAC,
  ERRORS_PAYMENT_INVALID_PAYMENT_METHOD_TYPE,
  ERRORS_PAYMENT_INVALID_PROVIDER_PAYMENT_CONFIG,
  ERRORS_PAYMENT_INVALID_USER_DETAILS,
  ERRORS_PAYMENT_PAYMENT_METHOD_CAPABILITY,
  ERRORS_PAYMENT_PAYMENT_METHOD_DECLINED,
  ERRORS_PAYMENT_PAYMENT_METHOD_PROCESSING_FAILURE,
  ERRORS_PAYMENT_PAYMENT_TOKEN_EXPIRED,
  ERRORS_PERSONAL_DETAILS_ADDRESS_LINE_ONE,
  ERRORS_PERSONAL_DETAILS_ADDRESS_LINE_TWO,
  ERRORS_PERSONAL_DETAILS_CITY,
  ERRORS_PERSONAL_DETAILS_DOB,
  ERRORS_PERSONAL_DETAILS_POSTAL,
  ERRORS_PERSONAL_DETAILS_STATE,
  ERRORS_PERSONAL_DETAILS_VERIFICATION_ATTEMPT,
  ERRORS_PERSONAL_DETAILS_VERIFICATION_DEBIT_FIRST_6,
  ERRORS_PERSONAL_DETAILS_VERIFICATION_DEBIT_LAST_4,
  ERRORS_PHONE_CHANGE_BUTTON,
  ERRORS_PHONE_CHANGE_DESCRIPTION,
  ERRORS_PHONE_CHANGE_TITLE,
  ERRORS_PHONE_VERIFICATION_INVALID_OTP,
  ERRORS_SERVICE_DOWN_BUTTON,
  ERRORS_SERVICE_DOWN_DESCRIPTION,
  ERRORS_SERVICE_DOWN_TITLE,
  ERRORS_SUMMARY_AMOUNT_LIMIT_HEADER,
  ERRORS_SUMMARY_AMOUNT_LIMIT_REACHED_HEADER,
  ERRORS_SUMMARY_AMOUNT_LIMIT_REACHED_TEXT,
  ERRORS_SUMMARY_AMOUNT_LIMIT_TEXT,
  ERRORS_SUMMARY_AMOUNT_LIMIT_TEXT_MAX,
  ERRORS_SUMMARY_FREQUENCY_LIMIT_HEADER,
  ERRORS_SUMMARY_FREQUENCY_LIMIT_TEXT,
  ERRORS_SUMMARY_RATE_CHANGE_HEADER,
  ERRORS_SUMMARY_RATE_CHANGE_TEXT,
  ERRORS_TRANSFER_ERROR_BUTTON,
  ERRORS_TRANSFER_ERROR_DESCRIPTION,
  ERRORS_TRANSFER_ERROR_REC_BUTTON,
  ERRORS_TRANSFER_ERROR_REC_DESCRIPTION,
  ERRORS_TRANSFER_ERROR_REC_TITLE,
  ERRORS_TRANSFER_ERROR_REC_TRY_AGAIN_BUTTON,
  ERRORS_TRANSFER_ERROR_TITLE,
  IDV_DESKTOP_PROMPT_TEXT,
  IDV_DESKTOP_PROMPT_TITLE,
  KYC_FAIL_HEADER_DESCRIPTION,
  KYC_FAIL_HEADER_TITLE,
  KYC_FAIL_HEADER_TRY_AGAIN,
  KYC_FAIL_HEADER_TRY_AGAIN_TWO,
  NOT_FOUND_BUTTON,
  NOT_FOUND_DESCRIPTION,
  NOT_FOUND_TITLE,
  PAYMENT_ACCOUNT_TITLE,
  PAYMENT_PERSONAL_TITLE,
  PAYMENT_SEND_AMOUNT_TITLE,
  PAYMENT_TITLE,
  PERSONAL_DETAILS_ADDRESS_ONE_LABEL,
  PERSONAL_DETAILS_ADDRESS_ONE_PLACEHOLDER,
  PERSONAL_DETAILS_ADDRESS_TWO_LABEL,
  PERSONAL_DETAILS_ADDRESS_TWO_PLACEHOLDER,
  PERSONAL_DETAILS_CITY_LABEL,
  PERSONAL_DETAILS_CITY_PLACEHOLDER,
  PERSONAL_DETAILS_COUNTRY_LABEL,
  PERSONAL_DETAILS_COUNTRY_PLACEHOLDER,
  PERSONAL_DETAILS_DOB,
  PERSONAL_DETAILS_DOB_DAY_LABEL,
  PERSONAL_DETAILS_DOB_DAY_PLACEHOLDER,
  PERSONAL_DETAILS_DOB_MONTH_LABEL,
  PERSONAL_DETAILS_DOB_MONTH_PLACEHOLDER,
  PERSONAL_DETAILS_DOB_YEAR_LABEL,
  PERSONAL_DETAILS_DOB_YEAR_PLACEHOLDER,
  PERSONAL_DETAILS_POSTAL_LABEL,
  PERSONAL_DETAILS_POSTAL_PLACEHOLDER,
  PERSONAL_DETAILS_STATE_LABEL,
  PERSONAL_DETAILS_STATE_PLACEHOLDER,
  PERSONAL_DETAILS_TITLE,
  PERSONAL_DETAILS_VERIFICATION_DEBIT_CARD_FIRST_SIX_LABEL,
  PERSONAL_DETAILS_VERIFICATION_DEBIT_CARD_FIRST_SIX_PLACEHOLDER,
  PERSONAL_DETAILS_VERIFICATION_DEBIT_CARD_LAST_FOUR_LABEL,
  PERSONAL_DETAILS_VERIFICATION_DEBIT_CARD_LAST_FOUR_PLACEHOLDER,
  PERSONAL_DETAILS_VERIFICATION_HEADER,
  PERSONAL_DETAILS_VERIFICATION_INT_PLACEHOLDER,
  PERSONAL_DETAILS_VERIFICATION_PHONE_LABEL,
  PERSONAL_DETAILS_VERIFICATION_PHONE_PLACEHOLDER,
  PERSONAL_DETAILS_VERIFICATION_SUB_HEADER,
  PHONE_VERIFICATION_CANT_ACCESS,
  PHONE_VERIFICATION_CONTACT_SUPPORT,
  PHONE_VERIFICATION_NEW_CODE,
  PHONE_VERIFICATION_RETRY_COUNTDOWN,
  PHONE_VERIFICATION_SENT_TO_NUMBER,
  PHONE_VERIFICATION_UPDATE_PHONE_NUMBER,
  RECEIPT_FOOTER_CANCEL,
  RECEIPT_FOOTER_CANCEL_SUB,
  RECEIPT_FOOTER_CFPB,
  RECEIPT_FOOTER_DISPUTE_ONE,
  RECEIPT_FOOTER_DISPUTE_TWO,
  RECEIPT_FOOTER_EMAIL,
  RECEIPT_FOOTER_FAX,
  RECEIPT_FOOTER_PHONE,
  RECEIPT_FOOTER_SUB_TITLE,
  RECEIPT_FOOTER_TITLE,
  RECEIPT_FUNDS_AVAILABLE,
  RECEIPT_OVERVIEW,
  RECEIPT_OVERVIEW_AMOUNT,
  RECEIPT_OVERVIEW_EXCHANGE_RATE,
  RECEIPT_OVERVIEW_TRANSFER_FEE,
  RECEIPT_OVERVIEW_TRANSFER_TOTAL,
  RECEIPT_PAID_FROM,
  RECEIPT_PAID_FROM_ADDRESS,
  RECEIPT_PAID_FROM_SENDER_NAME,
  RECEIPT_SEND_TO,
  RECEIPT_SENT_MONEY_TO,
  RECEIPT_SENT_TO_COUNTRY,
  RECEIPT_SENT_TO_RECIPIENT_NAME,
  RECEIPT_SENT_TO_TOTAL,
  RECEIPT_TRANSFER_DATA,
  RECEIPT_TRANSFER_DETAILS,
  RECEIPT_TRANSFER_TRANSACTION,
  RECEIPT_TRANSFER_YOUR_DETAILS,
  SECURE_PAYMENT_DEBIT_CARD,
  SECURE_PAYMENT_DEBIT_ONLY,
  SECURE_PAYMENT_DIFFERENT_CARD,
  SECURE_PAYMENT_REVIEW_SUMMARY,
  SECURE_PAYMENT_TITLE,
  SEND_AMOUNT_AMOUNT,
  SEND_AMOUNT_TRANSFER_FEE_PERCENT_OFF,
  SEND_AMOUNT_AMOUNT_FREQUENCY_LIMIT,
  SEND_AMOUNT_AMOUNT_PLACEHOLDER,
  SEND_AMOUNT_CAN_ONLY_TRANSFER_AT_A_TIME,
  SEND_AMOUNT_CONTINUE,
  SEND_AMOUNT_COUNT_FREQUENCY_LIMIT,
  SEND_AMOUNT_EXCHANGE_RATE,
  SEND_AMOUNT_FREQUENCY_LIMIT,
  SEND_AMOUNT_HOW_MUCH,
  SEND_AMOUNT_INVALID_ACCOUNT_TRANSFER,
  SEND_AMOUNT_INVALID_ACTION,
  SEND_AMOUNT_LIMIT_REACHED,
  SEND_AMOUNT_RECIPIENT_GETS,
  SEND_AMOUNT_TITLE,
  SEND_AMOUNT_TOTAL,
  SEND_AMOUNT_TRANSFER_FEE,
  SEND_AMOUNT_VALID_AMOUNT,
  SUMMARY_BUTTON,
  SUMMARY_DESCRIPTION,
  SUMMARY_DISMISS,
  SUMMARY_EXCHANGE_RATE,
  SUMMARY_PAYMENT_DETAILS,
  SUMMARY_PAYMENT_METHOD,
  SUMMARY_RECIPIENT_GETS,
  SUMMARY_SENDING,
  SUMMARY_TERMS,
  SUMMARY_TITLE,
  SUMMARY_TRANSFER_FEE,
  SUMMARY_TRANSFER_TOTAL,
  SUPPORT_MODAL_BUTTON,
  SUPPORT_MODAL_HEADER,
  SUPPORT_MODAL_TEXT,
  TRANSACTION_SUMMARY_TITLE,
  TRANSFER_ERROR_DESCRIPTION,
  TRANSFER_ERROR_SOMETHING_WRONG,
  TRANSFER_ERROR_TRY_AGAIN,
  TRANSFER_SUCCESS_DESCRIPTION,
  TRANSFER_SUCCESS_SEND_MORE,
  TRANSFER_SUCCESS_SUCCESSFUL,
  TRANSFER_SUCCESS_VIEW_RECEIPT,
  UPDATE_PHONE_HEADER,
  UPDATE_PHONE_INT_PLACEHOLDER,
  UPDATE_PHONE_PHONE_LABEL,
  UPDATE_PHONE_PHONE_PLACEHOLDER,
  VERIFICATION_ACCOUNT,
  VERIFICATION_SEND_AMOUNT,
  VERIFICATION_TITLE,
} = constants;

export default {
  [COMMON_KEY]: {
    [COMMON_EDIT]: 'Edit',
    [COMMON_SERVICE_ERROR]: 'Something went wrong',
    [COMMON_CONTINUE]: 'Continue',
    [COMMON_BACK]: 'Back',
    [COMMON_RETRY]: 'Retry',
    [COMMON_ERROR]: 'Error',
    [COMMON_COPIED]: 'Copied to clipboard',
    [COMMON_SHARE]: 'Share',
    [COMMON_UPGRADE_ACCOUNT]: 'Or, upgrade your account to',
    [COMMON_SEND_MORE_MONEY_CTA]: '<a href="#" id="sendMoreMoneyCta" />',
    [COMMON_SEND_MORE_MONEY]: 'send more money',
    [COMMON_CANCEL]: 'Cancel',
    [COMMON_CONTACT_US]:
      "Need help?  <a href='mailto:support@palla.app?subject=Support%20Request{sessionId}' target='_blank'>Contact support</a>",
  },
  [ERRORS_KEY]: {
    [ERRORS_IDV_UPGRADE_FAIL]:
      "Unfortunately, we were not able to upgrade your account. Please contact <a href='mailto:support@palla.app?subject=Support%20Request{sessionId}' target='_blank'>support@palla.app</a> to upgrade your account. You may still transfer for your existing limits",
    [ERRORS_GENERIC_TITLE]: 'Something went wrong',
    [ERRORS_GENERIC_DESCRIPTION]:
      'Please try again or get in touch with support.',
    [ERRORS_GENERIC_BUTTON]: 'Contact support',
    [ERRORS_INVALID_LINK_TITLE]: 'This link is invalid',
    [ERRORS_INVALID_LINK_DESCRIPTION]:
      'Get in contact with your friend or family member to receive a new transfer link.<br /><br />Feel free to get in touch with support.',
    [ERRORS_INVALID_LINK_BUTTON]: 'Contact support',
    [ERRORS_KYC_FAIL_TITLE]: 'We could not verify your identity',
    [ERRORS_KYC_FAIL_DESCRIPTION]:
      'Unfortunately, we were unable to verify your identity. You are unable to transfer funds internationally until we complete this process.<br /><br />Please get in touch with support.',
    [ERRORS_KYC_FAIL_BUTTON]: 'Contact support',
    [ERRORS_BLOCKED_TITLE]: 'We are unable to proceed with your transfer',
    [ERRORS_BLOCKED_DESCRIPTION]:
      'Unfortunately, we were unable to proceed with your transfer.<br /><br />Please get in touch with support.',
    [ERRORS_BLOCKED_BUTTON]: 'Contact support',
    [ERRORS_TRANSFER_ERROR_REC_TITLE]: 'Something went wrong',
    [ERRORS_TRANSFER_ERROR_REC_DESCRIPTION]:
      'Your transaction wasn’t able to be processed. Don’t worry, your money hasn’t been sent.<br /><br />Please try again or get in touch with support.',
    [ERRORS_TRANSFER_ERROR_REC_BUTTON]: 'Contact support',
    [ERRORS_TRANSFER_ERROR_REC_TRY_AGAIN_BUTTON]: 'Try again',
    [ERRORS_TRANSFER_ERROR_TITLE]:
      'We are unable to proceed with your transfer',
    [ERRORS_TRANSFER_ERROR_DESCRIPTION]:
      'Unfortunately, we were unable to proceed with your transfer.<br /><br />Please get in touch with support and we will work to resolve the issue. Thank you!',
    [ERRORS_TRANSFER_ERROR_BUTTON]: 'Contact support',
    [ERRORS_ACCOUNT_TITLE]: 'Share your link:',
    [ERRORS_ACCOUNT_INVALID_COUNTRY]:
      'To receive money in your account, share your link with friends and family members living in the U.S.',
    [ERRORS_ACCOUNT_WHITELIST_COUNTRY_PROCEED]:
      'You can also proceed with the transfer on your own from any country as long as you have the [following]:',
    [ERRORS_ACCOUNT_US_DEBIT]: 'U.S. debit card',
    [ERRORS_ACCOUNT_US_PHONE]: 'U.S. phone number',
    [ERRORS_ACCOUNT_US_ADDRESS]: 'U.S. address',
    [ERRORS_PAYMENT_CREDIT_CARD]: 'Invalid card type.  Please use debit card',
    [ERRORS_PAYMENT_DUPLICATE]:
      'This card is already registered. Please use a different card',
    [ERRORS_PAYMENT_INVALID]: 'Invalid card type',
    [ERRORS_PAYMENT_INVALID_ADDRESS]:
      'Please ensure your address matches the card on file.',
    [ERRORS_PAYMENT_INVALID_DETAILS]: 'Please ensure correct card information',
    [ERRORS_PAYMENT_INVALID_USER_DETAILS]:
      'Your payment card must be under your legal name',
    [ERRORS_PAYMENT_AMOUNT_LIMIT]: 'Your transfer limit has been reached',
    [ERRORS_PAYMENT_FREQUENCY_LIMIT]: 'Your transfer limit has been reached',
    [ERRORS_PAYMENT_INVALID_CONFIG]:
      'Something went wrong.  Please ensure correct card information',
    [ERRORS_PAYMENT_INVALID_DETAILS_ADDRESS]:
      'Your payment does not match address on file',
    [ERRORS_PAYMENT_INVALID_PAYMENT_DETAILS]:
      'Please ensure correct card information.',
    [ERRORS_PAYMENT_PAYMENT_TOKEN_EXPIRED]:
      'Please reenter your card information.',
    [ERRORS_PAYMENT_INVALID_PAYMENT_METHOD_DETAILS]:
      'Something went wrong. Please start over.',
    [ERRORS_PAYMENT_INVALID_PROVIDER_PAYMENT_CONFIG]:
      'Something went wrong. Please try again or contact support at support@palla.app.',
    [ERRORS_PAYMENT_INVALID_PAYMENT_METHOD_OFAC]: 'Something went wrong',
    [ERRORS_PAYMENT_PAYMENT_METHOD_DECLINED]:
      'Card Declined. Please try again or try a new card.',
    [ERRORS_PAYMENT_PAYMENT_METHOD_CAPABILITY]:
      'Card Error. Please try again or try a new card.',
    [ERRORS_PAYMENT_INVALID_PAYMENT_METHOD_TYPE]:
      'Invalid card type. Please use a debit card.',
    [ERRORS_PAYMENT_INVALID_PAYMENT_METHOD_CURRENCY]:
      'Card Error. Please try a new card.',
    [ERRORS_PAYMENT_PAYMENT_METHOD_PROCESSING_FAILURE]:
      'Processing card failed, please try again.',
    [ERRORS_PERSONAL_DETAILS_ADDRESS_LINE_ONE]: 'Please enter a valid address',
    [ERRORS_PERSONAL_DETAILS_ADDRESS_LINE_TWO]: 'Please enter a valid address',
    [ERRORS_PERSONAL_DETAILS_CITY]: 'Please enter a valid city',
    [ERRORS_PERSONAL_DETAILS_DOB]: 'Please enter a valid DOB',
    [ERRORS_PERSONAL_DETAILS_STATE]: 'Please enter a valid state',
    [ERRORS_PERSONAL_DETAILS_POSTAL]: 'Please enter a valid zip',
    [ERRORS_SUMMARY_RATE_CHANGE_HEADER]: 'The rate has changed',
    [ERRORS_SUMMARY_RATE_CHANGE_TEXT]:
      'Please double check the details again to confirm how much you will be sending',
    [ERRORS_SUMMARY_AMOUNT_LIMIT_REACHED_HEADER]:
      'Your transfer limit has been reached',
    [ERRORS_SUMMARY_AMOUNT_LIMIT_REACHED_TEXT]:
      'You can transfer again on {date}',
    [ERRORS_SUMMARY_AMOUNT_LIMIT_HEADER]:
      'Your transfer limit has been reached',
    [ERRORS_SUMMARY_AMOUNT_LIMIT_TEXT]:
      'You can only transfer up to {amount} until {date}',
    [ERRORS_SUMMARY_AMOUNT_LIMIT_TEXT_MAX]:
      'You have hit the maximum transfer limit.  You cannot transfer again until {date}',
    [ERRORS_SUMMARY_FREQUENCY_LIMIT_HEADER]:
      'Your transfer limit has been reached',
    [ERRORS_SUMMARY_FREQUENCY_LIMIT_TEXT]:
      'You can transfer up to {count} times per month. You can transfer again on {date}',
    [ERRORS_PHONE_VERIFICATION_INVALID_OTP]:
      'This code is invalid.  Please try again',
    [ERRORS_PERSONAL_DETAILS_VERIFICATION_DEBIT_FIRST_6]:
      'Please check your card numbers',
    [ERRORS_PERSONAL_DETAILS_VERIFICATION_DEBIT_LAST_4]:
      'Please check your card numbers',
    [ERRORS_PERSONAL_DETAILS_VERIFICATION_ATTEMPT]:
      'Please ensure your information below is correct.',
    [ERRORS_PHONE_CHANGE_DESCRIPTION]:
      'Please get in touch with support to proceed with changing your number.',
    [ERRORS_PHONE_CHANGE_TITLE]:
      'We are unable to proceed with changing your phone number',
    [ERRORS_PHONE_CHANGE_BUTTON]: 'Contact support',
    [ERRORS_SERVICE_DOWN_DESCRIPTION]:
      'We are experiencing some issues. We apologize for any inconvenience. Please try again later.',
    [ERRORS_SERVICE_DOWN_TITLE]: 'The system is down',
    [ERRORS_SERVICE_DOWN_BUTTON]: 'Try again',
  },
  [NOT_FOUND_KEY]: {
    [NOT_FOUND_TITLE]: 'This is an invalid URL',
    [NOT_FOUND_DESCRIPTION]:
      "Sorry this address isn't recognised. If you received it from a friend or family, please copy and paste the full URL and try again.",
    [NOT_FOUND_BUTTON]: 'Back to home',
  },
  [ABOUT_KEY]: {
    [ABOUT_TITLE]: 'Send money from the US to your family in {country}',
    [ABOUT_DESCRIPTION]: 'It works in 3 simple steps',
  },
  [DETAILS_KEY]: {
    [DETAILS_ACCOUNT]: 'Account details',
    [DETAILS_TITLE]: 'Enter personal details',
    [DETAILS_SEND_AMOUNT]: 'Send amount',
  },
  [VERIFICATION_KEY]: {
    [VERIFICATION_ACCOUNT]: 'Account details',
    [VERIFICATION_TITLE]: 'Enter the verification code',
    [VERIFICATION_SEND_AMOUNT]: 'Send amount',
  },
  [SUMMARY_KEY]: {
    [SUMMARY_TITLE]: 'Review summary',
    [SUMMARY_DESCRIPTION]: 'Please confirm the details before sending',
    [SUMMARY_SENDING]: 'You are sending',
    [SUMMARY_EXCHANGE_RATE]: 'Exchange rate',
    [SUMMARY_RECIPIENT_GETS]: 'Recipient gets',
    [SUMMARY_TRANSFER_FEE]: 'Transfer fee',
    [SUMMARY_PAYMENT_DETAILS]: 'Payment details',
    [SUMMARY_PAYMENT_METHOD]: 'Payment method',
    [SUMMARY_TRANSFER_TOTAL]: 'Total',
    [SUMMARY_BUTTON]: 'Send money',
    [SUMMARY_TERMS]:
      "Services provided by Palla Financial, Inc. By clicking 'Send money' you are confirming that you agree with the transfer details presented to you in this screen",
    [SUMMARY_DISMISS]: 'Okay',
  },
  [TRANSFER_ERROR_KEY]: {
    [TRANSFER_ERROR_SOMETHING_WRONG]: 'Something went wrong',
    [TRANSFER_ERROR_DESCRIPTION]:
      "An unknown error occurred and your transaction wasn't able to be processed.  Don't worry, your money hasn't been sent",
    [TRANSFER_ERROR_TRY_AGAIN]: 'Try again',
  },

  [TRANSFER_SUCCESS_KEY]: {
    [TRANSFER_SUCCESS_SUCCESSFUL]:
      '{amount} {currency} has been successfully sent to {name}!',
    [TRANSFER_SUCCESS_DESCRIPTION]:
      'The funds will be available within 30 minutes of this transaction. You will also receive an emailed receipt to confirm this transaction',
    [TRANSFER_SUCCESS_SEND_MORE]: 'Send more money to {name}',
    [TRANSFER_SUCCESS_VIEW_RECEIPT]: 'View receipt',
  },
  [PAYMENT_KEY]: {
    [PAYMENT_ACCOUNT_TITLE]: 'Account details',
    [PAYMENT_PERSONAL_TITLE]: 'Personal details',
    [PAYMENT_TITLE]: 'Secure payment',
    [PAYMENT_SEND_AMOUNT_TITLE]: 'Send amount',
  },
  [RECEIPT_KEY]: {
    [RECEIPT_FUNDS_AVAILABLE]:
      'Your funds will be available within 30 minutes of this transaction',
    [RECEIPT_TRANSFER_DETAILS]: 'Transfer details',
    [RECEIPT_TRANSFER_DATA]: 'Date',
    [RECEIPT_TRANSFER_TRANSACTION]: 'Transaction',
    [RECEIPT_TRANSFER_YOUR_DETAILS]: 'Your details',
    [RECEIPT_OVERVIEW]: 'Transfer overview',
    [RECEIPT_OVERVIEW_AMOUNT]: 'Transfer amount',
    [RECEIPT_OVERVIEW_EXCHANGE_RATE]: 'Exchange rate',
    [RECEIPT_OVERVIEW_TRANSFER_FEE]: 'Transfer fee',
    [RECEIPT_OVERVIEW_TRANSFER_TOTAL]: 'Transfer total',
    [RECEIPT_SEND_TO]: 'Send to',
    [RECEIPT_SENT_TO_RECIPIENT_NAME]: 'Recipient name',
    [RECEIPT_SENT_TO_COUNTRY]: 'Country',
    [RECEIPT_SENT_TO_TOTAL]: 'Total to recipient',
    [RECEIPT_PAID_FROM]: 'Paid from',
    [RECEIPT_PAID_FROM_SENDER_NAME]: 'Sender name',
    [RECEIPT_PAID_FROM_ADDRESS]: 'Address',
    [RECEIPT_SENT_MONEY_TO]: 'You sent {amount} {currency} to {name} on {date}',
  },
  [SUPPORT_MODAL_KEY]: {
    [SUPPORT_MODAL_HEADER]: "Can't verify this phone number?",
    [SUPPORT_MODAL_TEXT]:
      "Email us at <a id=\"support\" href='mailto:support@palla.app?subject=Support%20Request{sessionId}' target='_blank'>support@palla.app</a> and we will get back to you as soon as possible.",
    [SUPPORT_MODAL_BUTTON]: 'Contact support',
  },
  [ABOUT_LIST_KEY]: {
    [ABOUT_LIST_ASK]:
      'Ask your family member to share their magic link from their {partner} app',
    [ABOUT_LIST_CHOOSE]: 'Choose how much to send from your debit card',
    [ABOUT_LIST_INSTANT]:
      'The money is instantly received by your family member!',
  },
  [SEND_AMOUNT_KEY]: {
    [SEND_AMOUNT_HOW_MUCH]: 'How much money would you like to send to {name}?',
    [SEND_AMOUNT_TITLE]: 'Send amount',
    [SEND_AMOUNT_AMOUNT_PLACEHOLDER]: '0.00',
    [SEND_AMOUNT_CONTINUE]: 'Continue',
    [SEND_AMOUNT_RECIPIENT_GETS]: 'Recipient gets',
    [SEND_AMOUNT_EXCHANGE_RATE]: 'Exchange rate',
    [SEND_AMOUNT_TRANSFER_FEE]: 'Transfer fee',
    [SEND_AMOUNT_TOTAL]: 'Total',
    [SEND_AMOUNT_CAN_ONLY_TRANSFER_AT_A_TIME]:
      'You can only transfer up to {atATimeAmount} {currency} at a time',
    [SEND_AMOUNT_VALID_AMOUNT]:
      'Please enter a valid amount above {minAmount} {currency}',
    [SEND_AMOUNT_TRANSFER_FEE_PERCENT_OFF]: '{percentOff}% OFF',
    [SEND_AMOUNT_LIMIT_REACHED]: 'You can transfer again on {limitReachedDate}',
    [SEND_AMOUNT_FREQUENCY_LIMIT]:
      'You can only transfer up to {freqAmount} {currency} at this time',
    [SEND_AMOUNT_AMOUNT_FREQUENCY_LIMIT]:
      'You can only transfer up to {amtFreqAmount} {currency} until {amtFreqDate}',
    [SEND_AMOUNT_COUNT_FREQUENCY_LIMIT]:
      'You can only transfer {countFreqLimit} times per month.  You can transfer again on {countFreqDate}',
    [SEND_AMOUNT_INVALID_ACTION]: 'Invalid transfer action',
    [SEND_AMOUNT_INVALID_ACCOUNT_TRANSFER]:
      "Unable to send money to account.  Please contact <a href='mailto:support@palla.app?subject=Support%20Request{sessionId}' target='_blank'>support@palla.app</a>",
  },
  [BRAND_HEADER_KEY]: {
    [BRAND_HEADER_TITLE]: 'Thank ',
    [BRAND_HEADER_SEND_MONEY_INSTANTLY]:
      'For gratifying {recipient} for the great work they have done creating the product you are enjoying!',
  },
  [ACCOUNT_DETAILS_KEY]: {
    [ACCOUNT_DETAILS_TITLE]: 'Account details',
    [ACCOUNT_DETAILS_EMAIL_PLACEHOLDER]: 'adrian.ramireze@palla.app',
    [ACCOUNT_DETAILS_EMAIL_LABEL]: 'Email',
    [ACCOUNT_DETAILS_INT_PLACEHOLDER]: '+1',
    [ACCOUNT_DETAILS_PHONE_LABEL]: 'Phone number',
    [ACCOUNT_DETAILS_PHONE_PLACEHOLDER]: '(555)555-5555',
    [ACCOUNT_DETAILS_FIRST_NAME_PLACEHOLDER]: 'Adrian',
    [ACCOUNT_DETAILS_FIRST_NAME_LABEL]: 'First name',
    [ACCOUNT_DETAILS_LAST_NAME_PLACEHOLDER]: 'Ramirez',
    [ACCOUNT_DETAILS_LAST_NAME_LABEL]: 'Last name',
    [ACCOUNT_DETAILS_INVALID_EMAIL]: 'Please enter a valid email address',
    [ACCOUNT_DETAILS_INVALID_PHONE]: 'Please enter a valid phone number',
    [ACCOUNT_DETAILS_INVALID_FIRST_NAME]: 'Please enter a valid first name',
    [ACCOUNT_DETAILS_INVALID_LAST_NAME]: 'Please enter a valid last name',
    [ACCOUNT_DETAILS_PHONE_TAKEN]: 'This phone is already registered',
    [ACCOUNT_DETAILS_TERMS]:
      "By continuing you are agreeing to creating a Palla account as outlined in <a id=\"terms\" href='https://www.palla.app/terms-and-conditions' target='_blank'>terms and conditions</a> and <a id=\"privacy\" href='https://palla.app/privacy-policy' target='_blank'>privacy policy</a>. This will ensure that your details are safe and future transactions will be even easier",
  },
  [KYC_FAIL_KEY]: {
    [KYC_FAIL_HEADER_TITLE]: 'We could not verify your identity',
    [KYC_FAIL_HEADER_DESCRIPTION]:
      'Unfortunately, we were unable to verify your identity. You are unable to transfer funds internationally until we complete this process.<br /><br />Please get in touch with support.',
    [KYC_FAIL_HEADER_TRY_AGAIN]: 'Please try again or email us at ',
    [KYC_FAIL_HEADER_TRY_AGAIN_TWO]:
      ' and our staff will respond to you within 48 hours',
  },
  [TRANSACTION_SUMMARY_KEY]: {
    [TRANSACTION_SUMMARY_TITLE]: 'Summary',
  },
  [SECURE_PAYMENT_KEY]: {
    [SECURE_PAYMENT_DEBIT_CARD]: '{name} Debit Card {lastFour}',
    [SECURE_PAYMENT_TITLE]: 'Secure payment',
    [SECURE_PAYMENT_DIFFERENT_CARD]: 'Use a different card',
    [SECURE_PAYMENT_REVIEW_SUMMARY]: 'Review summary',
    [SECURE_PAYMENT_DEBIT_ONLY]: 'Only debit cards accepted',
  },
  [PERSONAL_DETAILS_KEY]: {
    [PERSONAL_DETAILS_TITLE]: 'Personal details',
    [PERSONAL_DETAILS_ADDRESS_ONE_PLACEHOLDER]: '123 Main St',
    [PERSONAL_DETAILS_ADDRESS_ONE_LABEL]: 'Address line 1',
    [PERSONAL_DETAILS_ADDRESS_TWO_PLACEHOLDER]: 'Apt 2',
    [PERSONAL_DETAILS_ADDRESS_TWO_LABEL]: 'Address line 2',
    [PERSONAL_DETAILS_CITY_PLACEHOLDER]: 'Springfield',
    [PERSONAL_DETAILS_CITY_LABEL]: 'City',
    [PERSONAL_DETAILS_STATE_PLACEHOLDER]: 'IL',
    [PERSONAL_DETAILS_STATE_LABEL]: 'State',
    [PERSONAL_DETAILS_POSTAL_PLACEHOLDER]: '12345',
    [PERSONAL_DETAILS_POSTAL_LABEL]: 'Zip Code',
    [PERSONAL_DETAILS_COUNTRY_PLACEHOLDER]: 'United States',
    [PERSONAL_DETAILS_COUNTRY_LABEL]: 'Country',
    [PERSONAL_DETAILS_DOB_DAY_PLACEHOLDER]: '31',
    [PERSONAL_DETAILS_DOB_DAY_LABEL]: 'DD',
    [PERSONAL_DETAILS_DOB_MONTH_PLACEHOLDER]: '12',
    [PERSONAL_DETAILS_DOB_MONTH_LABEL]: 'MM',
    [PERSONAL_DETAILS_DOB_YEAR_PLACEHOLDER]: '1999',
    [PERSONAL_DETAILS_DOB_YEAR_LABEL]: 'YYYY',
    [PERSONAL_DETAILS_DOB]: 'Date of birth',
    [PERSONAL_DETAILS_VERIFICATION_HEADER]: 'Personal information',
    [PERSONAL_DETAILS_VERIFICATION_SUB_HEADER]:
      'For security, please provide the following information.',
    [PERSONAL_DETAILS_VERIFICATION_PHONE_PLACEHOLDER]: '(555)555-5555',
    [PERSONAL_DETAILS_VERIFICATION_PHONE_LABEL]: 'Previous phone number',
    [PERSONAL_DETAILS_VERIFICATION_INT_PLACEHOLDER]: '+1',
    [PERSONAL_DETAILS_VERIFICATION_DEBIT_CARD_FIRST_SIX_LABEL]: 'Debit card',
    [PERSONAL_DETAILS_VERIFICATION_DEBIT_CARD_FIRST_SIX_PLACEHOLDER]:
      'First 6 digits',
    [PERSONAL_DETAILS_VERIFICATION_DEBIT_CARD_LAST_FOUR_LABEL]: 'Debit card',
    [PERSONAL_DETAILS_VERIFICATION_DEBIT_CARD_LAST_FOUR_PLACEHOLDER]:
      'Last 4 digits',
  },
  [PHONE_VERIFICATION_KEY]: {
    [PHONE_VERIFICATION_SENT_TO_NUMBER]:
      'Enter the verification code sent to {maskedNumber}',
    [PHONE_VERIFICATION_RETRY_COUNTDOWN]: 'Please wait ({number})',
    [PHONE_VERIFICATION_NEW_CODE]: 'Request a new code',
    [PHONE_VERIFICATION_CANT_ACCESS]: "Can't access this phone?",
    [PHONE_VERIFICATION_CONTACT_SUPPORT]: 'Contact support',
    [PHONE_VERIFICATION_UPDATE_PHONE_NUMBER]: 'Update phone number',
  },
  [EMAIL_VERIFICATION_KEY]: {
    [EMAIL_VERIFICATION_SENT_TO_HEADER]: 'Verify your email',
    [EMAIL_VERIFICATION_SUB_HEADER]:
      "In order to set your new phone number, we've sent a verification code to {email}. Please enter the code below to continue.",
    [EMAIL_VERIFICATION_NEW_CODE]: 'Send again',
    [EMAIL_VERIFICATION_NEW_CODE_HEADER]: "Didn't receive an email?",
  },
  [RECEIPT_FOOTER_KEY]: {
    [RECEIPT_FOOTER_TITLE]: 'Services provided by Palla Financial Inc.',
    [RECEIPT_FOOTER_SUB_TITLE]:
      "Recipient may receive less due to fees charged by the recipient's bank and foreign taxes.",
    [RECEIPT_FOOTER_DISPUTE_ONE]:
      'You have a right to dispute errors in your transaction. If you think there is an error in your transaction, please contact our customer service within 180 days at ',
    [RECEIPT_FOOTER_DISPUTE_TWO]: ' or call ',
    [RECEIPT_FOOTER_CANCEL]:
      'You can cancel your transaction for a full refund within 30 minutes of payment, unless the funds have been deposited. You can also contact us for a written explanation of your rights.',
    [RECEIPT_FOOTER_CANCEL_SUB]:
      'For questions or complaints about Palla Financial Inc, you may [contact]:',
    [RECEIPT_FOOTER_PHONE]: '[Ph]: ',
    [RECEIPT_FOOTER_EMAIL]: '[Email]: ',
    [RECEIPT_FOOTER_FAX]: '[Fax]: ',
    [RECEIPT_FOOTER_CFPB]: 'Consumer Financial Protection Bureau (US)',
  },
  [IDV_DESKTOP_KEY]: {
    [IDV_DESKTOP_PROMPT_TITLE]:
      'In order to proceed with your transaction we need to verify your ID',
    [IDV_DESKTOP_PROMPT_TEXT]:
      'On the next step you will scan a QR code with your mobile device to upload your ID and then proceed with your transfer. You don’t need to be U.S. citizen, we also accept foreign IDs.',
  },
  [UPDATE_PHONE_KEY]: {
    [UPDATE_PHONE_HEADER]: 'Update phone number',
    [UPDATE_PHONE_PHONE_PLACEHOLDER]: '(555)555-5555',
    [UPDATE_PHONE_PHONE_LABEL]: 'New phone number',
    [UPDATE_PHONE_INT_PLACEHOLDER]: '+1',
  },
};
