import { assign } from 'xstate';

const updatePalla = assign({
  palla: ({ palla }, { payload }) => ({
    // TODO - Add specificity to attributes being updated
    ...palla,
    ...payload.palla,
  }),
});

export default updatePalla;
