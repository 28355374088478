import { assign } from 'xstate';

// // Refresh extended state
// updateRate: assign({
//   exchangeAmount: (_, { data }) => data?.amount || 0.0,
//   exchangeRate: (_, { data }) => data?.rate || 0.0,
//   transferFee: (_, { data }) => data?.fee || 0.0,
//   exchangeCurrency: (_, { data }) => data?.currency || 0.0,
// }),
const updatePallaRate = assign({
  palla: (context, { payload }) => {
    const rate = payload.rate;
    return {
      ...context.palla,
      rate,
    };
  },
});

export default updatePallaRate;
