import updateTransfer from './updateTransfer';
import updateAccount from './updateAccount';
import updatePalla from './updatePalla';
import updatePallaRate from './updatePallaRate';
import updatePersonal from './updatePersonal';
import updatePayment from './upatePayment';
import updatePallaTransfer from './updatePallaTransfer';
import invalidateAuth from './invalidateAuth';
import updateLanguage from './updateLanguage';
import updateUserTrackingData from './updateUserTrackingData';
import setUserTrackingData from './setUserTrackingData';
import resetUserTrackingData from './resetUserTrackingData';
import updateIdentity from './updateIdentity';
import updateCompleteTransfer from './updateCompleteTransfer';
import setAccountBlocked from './setAccountBlocked';
import clearTransferAmount from './clearTransferAmount';
import updatePreferredLang from './updatePreferredLang';
import updateChallenge from './updateChallenge';

export default {
  setAccountBlocked,
  clearTransferAmount,
  updatePreferredLang,
  updateUserTrackingData,
  resetUserTrackingData,
  setUserTrackingData,
  updateChallenge,
  updateLanguage,
  updateTransfer,
  updateAccount,
  updatePalla,
  updatePersonal,
  updatePayment,
  updatePallaTransfer,
  updatePallaRate,
  invalidateAuth,
  updateIdentity,
  updateCompleteTransfer,
};
