import constants, {
  COMMON_KEY,
  ERRORS_KEY,
  NOT_FOUND_KEY,
  ABOUT_KEY,
  DETAILS_KEY,
  VERIFICATION_KEY,
  SUMMARY_KEY,
  TRANSFER_ERROR_KEY,
  TRANSFER_SUCCESS_KEY,
  PAYMENT_KEY,
  RECEIPT_KEY,
  SUPPORT_MODAL_KEY,
  ABOUT_LIST_KEY,
  SEND_AMOUNT_KEY,
  BRAND_HEADER_KEY,
  ACCOUNT_DETAILS_KEY,
  KYC_FAIL_KEY,
  TRANSACTION_SUMMARY_KEY,
  SECURE_PAYMENT_KEY,
  PHONE_VERIFICATION_KEY,
  EMAIL_VERIFICATION_KEY,
  RECEIPT_FOOTER_KEY,
  IDV_DESKTOP_KEY,
  PERSONAL_DETAILS_KEY,
  UPDATE_PHONE_KEY,
} from '../constants';

const {
  ABOUT_DESCRIPTION,
  ABOUT_LIST_ASK,
  ABOUT_LIST_CHOOSE,
  ABOUT_LIST_INSTANT,
  ABOUT_TITLE,
  ACCOUNT_DETAILS_EMAIL_LABEL,
  ACCOUNT_DETAILS_EMAIL_PLACEHOLDER,
  ACCOUNT_DETAILS_FIRST_NAME_LABEL,
  ACCOUNT_DETAILS_FIRST_NAME_PLACEHOLDER,
  ACCOUNT_DETAILS_INT_PLACEHOLDER,
  ACCOUNT_DETAILS_INVALID_EMAIL,
  ACCOUNT_DETAILS_INVALID_FIRST_NAME,
  ACCOUNT_DETAILS_INVALID_LAST_NAME,
  ACCOUNT_DETAILS_INVALID_PHONE,
  ACCOUNT_DETAILS_LAST_NAME_LABEL,
  ACCOUNT_DETAILS_LAST_NAME_PLACEHOLDER,
  ACCOUNT_DETAILS_PHONE_LABEL,
  ACCOUNT_DETAILS_PHONE_PLACEHOLDER,
  ACCOUNT_DETAILS_PHONE_TAKEN,
  ACCOUNT_DETAILS_TERMS,
  ACCOUNT_DETAILS_TITLE,
  BRAND_HEADER_SEND_MONEY_INSTANTLY,
  BRAND_HEADER_TITLE,
  COMMON_BACK,
  COMMON_CANCEL,
  COMMON_CONTACT_US,
  COMMON_CONTINUE,
  COMMON_COPIED,
  COMMON_EDIT,
  COMMON_ERROR,
  COMMON_RETRY,
  COMMON_SEND_MORE_MONEY,
  COMMON_SEND_MORE_MONEY_CTA,
  COMMON_SERVICE_ERROR,
  COMMON_SHARE,
  COMMON_UPGRADE_ACCOUNT,
  DETAILS_ACCOUNT,
  DETAILS_SEND_AMOUNT,
  DETAILS_TITLE,
  EMAIL_VERIFICATION_NEW_CODE,
  EMAIL_VERIFICATION_NEW_CODE_HEADER,
  EMAIL_VERIFICATION_SENT_TO_HEADER,
  EMAIL_VERIFICATION_SUB_HEADER,
  ERRORS_ACCOUNT_INVALID_COUNTRY,
  ERRORS_ACCOUNT_TITLE,
  ERRORS_ACCOUNT_US_ADDRESS,
  ERRORS_ACCOUNT_US_DEBIT,
  ERRORS_ACCOUNT_US_PHONE,
  ERRORS_ACCOUNT_WHITELIST_COUNTRY_PROCEED,
  ERRORS_BLOCKED_BUTTON,
  ERRORS_BLOCKED_DESCRIPTION,
  ERRORS_BLOCKED_TITLE,
  ERRORS_GENERIC_BUTTON,
  ERRORS_GENERIC_DESCRIPTION,
  ERRORS_GENERIC_TITLE,
  ERRORS_IDV_UPGRADE_FAIL,
  ERRORS_INVALID_LINK_BUTTON,
  ERRORS_INVALID_LINK_DESCRIPTION,
  ERRORS_INVALID_LINK_TITLE,
  ERRORS_KYC_FAIL_BUTTON,
  ERRORS_KYC_FAIL_DESCRIPTION,
  ERRORS_KYC_FAIL_TITLE,
  ERRORS_PAYMENT_AMOUNT_LIMIT,
  ERRORS_PAYMENT_CREDIT_CARD,
  ERRORS_PAYMENT_DUPLICATE,
  ERRORS_PAYMENT_FREQUENCY_LIMIT,
  ERRORS_PAYMENT_INVALID,
  ERRORS_PAYMENT_INVALID_ADDRESS,
  ERRORS_PAYMENT_INVALID_CONFIG,
  ERRORS_PAYMENT_INVALID_DETAILS,
  ERRORS_PAYMENT_INVALID_DETAILS_ADDRESS,
  ERRORS_PAYMENT_INVALID_PAYMENT_DETAILS,
  ERRORS_PAYMENT_INVALID_PAYMENT_METHOD_CURRENCY,
  ERRORS_PAYMENT_INVALID_PAYMENT_METHOD_DETAILS,
  ERRORS_PAYMENT_INVALID_PAYMENT_METHOD_OFAC,
  ERRORS_PAYMENT_INVALID_PAYMENT_METHOD_TYPE,
  ERRORS_PAYMENT_INVALID_PROVIDER_PAYMENT_CONFIG,
  ERRORS_PAYMENT_INVALID_USER_DETAILS,
  ERRORS_PAYMENT_PAYMENT_METHOD_CAPABILITY,
  ERRORS_PAYMENT_PAYMENT_METHOD_DECLINED,
  ERRORS_PAYMENT_PAYMENT_METHOD_PROCESSING_FAILURE,
  ERRORS_PAYMENT_PAYMENT_TOKEN_EXPIRED,
  ERRORS_PERSONAL_DETAILS_ADDRESS_LINE_ONE,
  ERRORS_PERSONAL_DETAILS_ADDRESS_LINE_TWO,
  ERRORS_PERSONAL_DETAILS_CITY,
  ERRORS_PERSONAL_DETAILS_DOB,
  ERRORS_PERSONAL_DETAILS_POSTAL,
  ERRORS_PERSONAL_DETAILS_STATE,
  ERRORS_PERSONAL_DETAILS_VERIFICATION_ATTEMPT,
  ERRORS_PERSONAL_DETAILS_VERIFICATION_DEBIT_FIRST_6,
  ERRORS_PERSONAL_DETAILS_VERIFICATION_DEBIT_LAST_4,
  ERRORS_PHONE_CHANGE_BUTTON,
  ERRORS_PHONE_CHANGE_DESCRIPTION,
  ERRORS_PHONE_CHANGE_TITLE,
  ERRORS_PHONE_VERIFICATION_INVALID_OTP,
  ERRORS_SERVICE_DOWN_BUTTON,
  ERRORS_SERVICE_DOWN_DESCRIPTION,
  ERRORS_SERVICE_DOWN_TITLE,
  ERRORS_SUMMARY_AMOUNT_LIMIT_HEADER,
  ERRORS_SUMMARY_AMOUNT_LIMIT_REACHED_HEADER,
  ERRORS_SUMMARY_AMOUNT_LIMIT_REACHED_TEXT,
  ERRORS_SUMMARY_AMOUNT_LIMIT_TEXT,
  ERRORS_SUMMARY_AMOUNT_LIMIT_TEXT_MAX,
  ERRORS_SUMMARY_FREQUENCY_LIMIT_HEADER,
  ERRORS_SUMMARY_FREQUENCY_LIMIT_TEXT,
  ERRORS_SUMMARY_RATE_CHANGE_HEADER,
  ERRORS_SUMMARY_RATE_CHANGE_TEXT,
  ERRORS_TRANSFER_ERROR_BUTTON,
  ERRORS_TRANSFER_ERROR_DESCRIPTION,
  ERRORS_TRANSFER_ERROR_REC_BUTTON,
  ERRORS_TRANSFER_ERROR_REC_DESCRIPTION,
  ERRORS_TRANSFER_ERROR_REC_TITLE,
  ERRORS_TRANSFER_ERROR_REC_TRY_AGAIN_BUTTON,
  ERRORS_TRANSFER_ERROR_TITLE,
  IDV_DESKTOP_PROMPT_TEXT,
  IDV_DESKTOP_PROMPT_TITLE,
  KYC_FAIL_HEADER_DESCRIPTION,
  KYC_FAIL_HEADER_TITLE,
  KYC_FAIL_HEADER_TRY_AGAIN,
  KYC_FAIL_HEADER_TRY_AGAIN_TWO,
  NOT_FOUND_BUTTON,
  NOT_FOUND_DESCRIPTION,
  NOT_FOUND_TITLE,
  PAYMENT_ACCOUNT_TITLE,
  PAYMENT_PERSONAL_TITLE,
  PAYMENT_SEND_AMOUNT_TITLE,
  PAYMENT_TITLE,
  PERSONAL_DETAILS_ADDRESS_ONE_LABEL,
  PERSONAL_DETAILS_ADDRESS_ONE_PLACEHOLDER,
  PERSONAL_DETAILS_ADDRESS_TWO_LABEL,
  PERSONAL_DETAILS_ADDRESS_TWO_PLACEHOLDER,
  PERSONAL_DETAILS_CITY_LABEL,
  PERSONAL_DETAILS_CITY_PLACEHOLDER,
  PERSONAL_DETAILS_COUNTRY_LABEL,
  PERSONAL_DETAILS_COUNTRY_PLACEHOLDER,
  PERSONAL_DETAILS_DOB,
  PERSONAL_DETAILS_DOB_DAY_LABEL,
  PERSONAL_DETAILS_DOB_DAY_PLACEHOLDER,
  PERSONAL_DETAILS_DOB_MONTH_LABEL,
  PERSONAL_DETAILS_DOB_MONTH_PLACEHOLDER,
  PERSONAL_DETAILS_DOB_YEAR_LABEL,
  PERSONAL_DETAILS_DOB_YEAR_PLACEHOLDER,
  PERSONAL_DETAILS_POSTAL_LABEL,
  PERSONAL_DETAILS_POSTAL_PLACEHOLDER,
  PERSONAL_DETAILS_STATE_LABEL,
  PERSONAL_DETAILS_STATE_PLACEHOLDER,
  PERSONAL_DETAILS_TITLE,
  PERSONAL_DETAILS_VERIFICATION_DEBIT_CARD_FIRST_SIX_LABEL,
  PERSONAL_DETAILS_VERIFICATION_DEBIT_CARD_FIRST_SIX_PLACEHOLDER,
  PERSONAL_DETAILS_VERIFICATION_DEBIT_CARD_LAST_FOUR_LABEL,
  PERSONAL_DETAILS_VERIFICATION_DEBIT_CARD_LAST_FOUR_PLACEHOLDER,
  PERSONAL_DETAILS_VERIFICATION_HEADER,
  PERSONAL_DETAILS_VERIFICATION_INT_PLACEHOLDER,
  PERSONAL_DETAILS_VERIFICATION_PHONE_LABEL,
  PERSONAL_DETAILS_VERIFICATION_PHONE_PLACEHOLDER,
  PERSONAL_DETAILS_VERIFICATION_SUB_HEADER,
  PHONE_VERIFICATION_CANT_ACCESS,
  PHONE_VERIFICATION_CONTACT_SUPPORT,
  PHONE_VERIFICATION_NEW_CODE,
  PHONE_VERIFICATION_RETRY_COUNTDOWN,
  PHONE_VERIFICATION_SENT_TO_NUMBER,
  PHONE_VERIFICATION_UPDATE_PHONE_NUMBER,
  RECEIPT_FOOTER_CANCEL,
  RECEIPT_FOOTER_CANCEL_SUB,
  RECEIPT_FOOTER_CFPB,
  RECEIPT_FOOTER_DISPUTE_ONE,
  RECEIPT_FOOTER_DISPUTE_TWO,
  RECEIPT_FOOTER_EMAIL,
  RECEIPT_FOOTER_FAX,
  RECEIPT_FOOTER_PHONE,
  RECEIPT_FOOTER_SUB_TITLE,
  RECEIPT_FOOTER_TITLE,
  RECEIPT_FUNDS_AVAILABLE,
  RECEIPT_OVERVIEW,
  RECEIPT_OVERVIEW_AMOUNT,
  RECEIPT_OVERVIEW_EXCHANGE_RATE,
  RECEIPT_OVERVIEW_TRANSFER_FEE,
  RECEIPT_OVERVIEW_TRANSFER_TOTAL,
  RECEIPT_PAID_FROM,
  RECEIPT_PAID_FROM_ADDRESS,
  RECEIPT_PAID_FROM_SENDER_NAME,
  RECEIPT_SEND_TO,
  RECEIPT_SENT_MONEY_TO,
  RECEIPT_SENT_TO_COUNTRY,
  RECEIPT_SENT_TO_RECIPIENT_NAME,
  RECEIPT_SENT_TO_TOTAL,
  RECEIPT_TRANSFER_DATA,
  RECEIPT_TRANSFER_DETAILS,
  RECEIPT_TRANSFER_TRANSACTION,
  RECEIPT_TRANSFER_YOUR_DETAILS,
  SECURE_PAYMENT_DEBIT_CARD,
  SECURE_PAYMENT_DEBIT_ONLY,
  SECURE_PAYMENT_DIFFERENT_CARD,
  SECURE_PAYMENT_REVIEW_SUMMARY,
  SECURE_PAYMENT_TITLE,
  SEND_AMOUNT_AMOUNT,
  SEND_AMOUNT_TRANSFER_FEE_PERCENT_OFF,
  SEND_AMOUNT_AMOUNT_FREQUENCY_LIMIT,
  SEND_AMOUNT_AMOUNT_PLACEHOLDER,
  SEND_AMOUNT_CAN_ONLY_TRANSFER_AT_A_TIME,
  SEND_AMOUNT_CONTINUE,
  SEND_AMOUNT_COUNT_FREQUENCY_LIMIT,
  SEND_AMOUNT_EXCHANGE_RATE,
  SEND_AMOUNT_FREQUENCY_LIMIT,
  SEND_AMOUNT_HOW_MUCH,
  SEND_AMOUNT_INVALID_ACCOUNT_TRANSFER,
  SEND_AMOUNT_INVALID_ACTION,
  SEND_AMOUNT_LIMIT_REACHED,
  SEND_AMOUNT_RECIPIENT_GETS,
  SEND_AMOUNT_TITLE,
  SEND_AMOUNT_TOTAL,
  SEND_AMOUNT_TRANSFER_FEE,
  SEND_AMOUNT_VALID_AMOUNT,
  SUMMARY_BUTTON,
  SUMMARY_DESCRIPTION,
  SUMMARY_DISMISS,
  SUMMARY_EXCHANGE_RATE,
  SUMMARY_PAYMENT_DETAILS,
  SUMMARY_PAYMENT_METHOD,
  SUMMARY_RECIPIENT_GETS,
  SUMMARY_SENDING,
  SUMMARY_TERMS,
  SUMMARY_TITLE,
  SUMMARY_TRANSFER_FEE,
  SUMMARY_TRANSFER_TOTAL,
  SUPPORT_MODAL_BUTTON,
  SUPPORT_MODAL_HEADER,
  SUPPORT_MODAL_TEXT,
  TRANSACTION_SUMMARY_TITLE,
  TRANSFER_ERROR_DESCRIPTION,
  TRANSFER_ERROR_SOMETHING_WRONG,
  TRANSFER_ERROR_TRY_AGAIN,
  TRANSFER_SUCCESS_DESCRIPTION,
  TRANSFER_SUCCESS_SEND_MORE,
  TRANSFER_SUCCESS_SUCCESSFUL,
  TRANSFER_SUCCESS_VIEW_RECEIPT,
  UPDATE_PHONE_HEADER,
  UPDATE_PHONE_INT_PLACEHOLDER,
  UPDATE_PHONE_PHONE_LABEL,
  UPDATE_PHONE_PHONE_PLACEHOLDER,
  VERIFICATION_ACCOUNT,
  VERIFICATION_SEND_AMOUNT,
  VERIFICATION_TITLE,
} = constants;

export default {
  [COMMON_KEY]: {
    [COMMON_EDIT]: 'Editar',
    [COMMON_SERVICE_ERROR]: 'Algo salió mal',
    [COMMON_CONTINUE]: 'Continuar',
    [COMMON_BACK]: 'Atras',
    [COMMON_RETRY]: 'Volver a intentar',
    [COMMON_ERROR]: 'Error',
    [COMMON_COPIED]: 'Copiada al portapapeles',
    [COMMON_SHARE]: 'Copiar',
    [COMMON_UPGRADE_ACCOUNT]: 'O, sube tu cuenta de nivel para',
    [COMMON_SEND_MORE_MONEY_CTA]: '<a href="#" id="sendMoreMoneyCta" />',
    [COMMON_SEND_MORE_MONEY]: 'enviar más dinero',
    [COMMON_CANCEL]: 'Cancelar',
    [COMMON_CONTACT_US]:
      "Necesitas ayuda?  <a href='mailto:support@palla.app?subject=Support%20Request{sessionId}' target='_blank'>Contacta a soporte</a>",
  },
  [ERRORS_KEY]: {
    [ERRORS_IDV_UPGRADE_FAIL]:
      "Desafortunadamente no pudimos subir su cuenta de nivel. Por favor contacte a <a href='mailto:support@palla.app?subject=Support%20Request{sessionId}' target='_blank'>support@palla.app</a> para hacerlo. Puede seguir transfiriendo hasta su límite existente",
    [ERRORS_GENERIC_TITLE]: 'Algo salió mal',
    [ERRORS_GENERIC_DESCRIPTION]:
      'Por favor, vuelva a intentarlo de nuevo o póngase en contacto con el soporte.',
    [ERRORS_GENERIC_BUTTON]: 'Contacta a soporte',
    [ERRORS_INVALID_LINK_TITLE]: 'Este enlace es inválido',
    [ERRORS_INVALID_LINK_DESCRIPTION]:
      'Póngase en contacto con su amigo o familiar para recibir un nuevo enlace de transferencia.<br /><br />No dude en ponerse en contacto con el soporte.',
    [ERRORS_INVALID_LINK_BUTTON]: 'Contacta a soporte',
    [ERRORS_KYC_FAIL_TITLE]: 'No pudimos verificar tu identidad',
    [ERRORS_KYC_FAIL_DESCRIPTION]:
      'Desafortunadamente, no pudimos verificar su identidad. No puede transferir fondos internacionalmente hasta que completemos este proceso.<br /><br />Por favor, póngase en contacto con el soporte.',
    [ERRORS_KYC_FAIL_BUTTON]: 'Contacta a soporte',
    [ERRORS_BLOCKED_TITLE]: 'No podemos continuar con su transferencia',
    [ERRORS_BLOCKED_DESCRIPTION]:
      'Desafortunadamente, no pudimos continuar con su transferencia.<br /><br />Por favor, póngase en contacto con el soporte.',
    [ERRORS_BLOCKED_BUTTON]: 'Contacta a soporte',
    [ERRORS_TRANSFER_ERROR_REC_TITLE]: 'Algo salió mal',
    [ERRORS_TRANSFER_ERROR_REC_DESCRIPTION]:
      'Su transacción no pudo ser procesada. No te preocupes, tu dinero no ha sido enviado.<br /><br />Por favor, vuelva a intentarlo de nuevo o póngase en contacto con el soporte.',
    [ERRORS_TRANSFER_ERROR_REC_BUTTON]: 'Contacta a soporte',
    [ERRORS_TRANSFER_ERROR_REC_TRY_AGAIN_BUTTON]: 'Inténtalo de nuevo',
    [ERRORS_TRANSFER_ERROR_TITLE]: 'No podemos continuar con su transferencia',
    [ERRORS_TRANSFER_ERROR_DESCRIPTION]:
      'Desafortunadamente, no pudimos continuar con su transferencia.<br /><br />Por favor, póngase en contacto con el soporte y trabajaremos para resolver el problema. Gracias!',
    [ERRORS_TRANSFER_ERROR_BUTTON]: 'Contacta a soporte',
    [ERRORS_ACCOUNT_TITLE]: 'Comparte tu enlace:',
    [ERRORS_ACCOUNT_INVALID_COUNTRY]:
      'Para recibir dinero en su cuenta, comparta su enlace con amigos y familiares que viven en los Estados Unidos',
    [ERRORS_ACCOUNT_WHITELIST_COUNTRY_PROCEED]:
      'También puedes realizar la transferencia por tu cuenta desde cualquier país siempre que dispongas de lo [siguiente]:',
    [ERRORS_ACCOUNT_US_DEBIT]: 'Tarjeta de débito de EE. UU.',
    [ERRORS_ACCOUNT_US_PHONE]: 'Número de teléfono de EE. UU.',
    [ERRORS_ACCOUNT_US_ADDRESS]: 'Dirección de EE. UU.',
    [ERRORS_PAYMENT_CREDIT_CARD]:
      'Tipo de tarjeta inválido. Por favor use tarjeta de débito',
    [ERRORS_PAYMENT_DUPLICATE]:
      'Esta tarjeta ya está registrada. Utilice una tarjeta diferente',
    [ERRORS_PAYMENT_INVALID]: 'Tipo de tarjeta inválido',
    [ERRORS_PAYMENT_INVALID_ADDRESS]:
      'Por favor asegúrate que tu dirección sea la misma de la tarjeta registrada',
    [ERRORS_PAYMENT_INVALID_DETAILS]:
      'Asegúrese de que la información de la tarjeta sea correcta',
    [ERRORS_PAYMENT_INVALID_USER_DETAILS]:
      'Su tarjeta de pago debe estar bajo su nombre legal',
    [ERRORS_PAYMENT_AMOUNT_LIMIT]: 'Alcanzaste el límite de transferencias',
    [ERRORS_PAYMENT_FREQUENCY_LIMIT]: 'Alcanzaste el límite de transferencias',
    [ERRORS_PAYMENT_INVALID_CONFIG]:
      'Algo salió mal.  Asegúrese de que la información de la tarjeta sea correcta',
    [ERRORS_PAYMENT_INVALID_DETAILS_ADDRESS]:
      'Su pago no coincide con la dirección registrada',
    [ERRORS_PAYMENT_INVALID_PAYMENT_DETAILS]:
      'Asegúrese de que la información de la tarjeta sea correcta.',
    [ERRORS_PAYMENT_PAYMENT_TOKEN_EXPIRED]:
      'Por favor reingresar la información de su tarjeta.',
    [ERRORS_PAYMENT_INVALID_PAYMENT_METHOD_DETAILS]:
      'Algo salió mal. Por favor inténtelo de nuevo.',
    [ERRORS_PAYMENT_INVALID_PROVIDER_PAYMENT_CONFIG]:
      'Algo salió mal. Por favor inténtelo de nuevo o escriba a support@palla.app',
    [ERRORS_PAYMENT_INVALID_PAYMENT_METHOD_OFAC]: 'Algo salió mal',
    [ERRORS_PAYMENT_PAYMENT_METHOD_DECLINED]:
      'Tarjeta Rechazada. Por favor inténtelo de nuevo o use otra tarjeta.',
    [ERRORS_PAYMENT_PAYMENT_METHOD_CAPABILITY]:
      'Error de tarjeta. Por favor inténtelo de nuevo o use otra tarjeta.',
    [ERRORS_PAYMENT_INVALID_PAYMENT_METHOD_TYPE]:
      'Tipo de tarjeta inválido. Por favor use una tarjeta de débito.',
    [ERRORS_PAYMENT_INVALID_PAYMENT_METHOD_CURRENCY]:
      'Error de tarjeta. Por favor use otra tarjeta.',
    [ERRORS_PAYMENT_PAYMENT_METHOD_PROCESSING_FAILURE]:
      'Fallo de procesamiento de tarjeta. Por favor inténtelo de nuevo.',
    [ERRORS_PERSONAL_DETAILS_ADDRESS_LINE_ONE]:
      'Por favor ingresa una dirección válida',
    [ERRORS_PERSONAL_DETAILS_ADDRESS_LINE_TWO]:
      'Por favor ingresa una dirección válida',
    [ERRORS_PERSONAL_DETAILS_CITY]: 'Por favor ingresa una ciudad válida',
    [ERRORS_PERSONAL_DETAILS_DOB]:
      'Por favor ingresa una fecha de nacimiento válida',
    [ERRORS_PERSONAL_DETAILS_STATE]: 'Por favor ingresa un estado válido',
    [ERRORS_PERSONAL_DETAILS_POSTAL]:
      'Por favor ingresa un código postal válido',
    [ERRORS_SUMMARY_RATE_CHANGE_HEADER]: 'El tipo de cambio ha cambiado',
    [ERRORS_SUMMARY_RATE_CHANGE_TEXT]:
      'Por favor revisa los detalles de nuevo para confirmar cuanto estarás enviando',
    [ERRORS_SUMMARY_AMOUNT_LIMIT_REACHED_HEADER]:
      'Alcanzaste el límite de transferencias',
    [ERRORS_SUMMARY_AMOUNT_LIMIT_REACHED_TEXT]:
      'Puedes volver a transferir en {date}',
    [ERRORS_SUMMARY_AMOUNT_LIMIT_HEADER]:
      'Alcanzaste el límite de transferencias',
    [ERRORS_SUMMARY_AMOUNT_LIMIT_TEXT]:
      'Sólo puedes transferir {amount} hasta {date}',
    [ERRORS_SUMMARY_AMOUNT_LIMIT_TEXT_MAX]:
      'Has alcanzado el límite máximo de transferencia. No puedes transferir de nuevo hasta el {date}',
    [ERRORS_SUMMARY_FREQUENCY_LIMIT_HEADER]:
      'Alcanzaste el límite de transferencias',
    [ERRORS_SUMMARY_FREQUENCY_LIMIT_TEXT]:
      'Puedes transferir hast {count} por mes. Puedes volver a transferir en {date}',
    [ERRORS_PHONE_VERIFICATION_INVALID_OTP]:
      'Este código es inválido. Por favor inténtalo de nuevo',
    [ERRORS_PERSONAL_DETAILS_VERIFICATION_DEBIT_FIRST_6]:
      'Por favor verifique los números de su tarjeta',
    [ERRORS_PERSONAL_DETAILS_VERIFICATION_DEBIT_LAST_4]:
      'Por favor verifique los números de su tarjeta',
    [ERRORS_PERSONAL_DETAILS_VERIFICATION_ATTEMPT]:
      'Por favor, asegúrese de que su información abajo sea correcta.',
    [ERRORS_PHONE_CHANGE_DESCRIPTION]:
      'Por favor, póngase en contacto con el soporte para continuar con el cambio de su número.',
    [ERRORS_PHONE_CHANGE_TITLE]:
      'No podemos continuar con el cambio de su número de teléfono',
    [ERRORS_PHONE_CHANGE_BUTTON]: 'Contacta a soporte',
    [ERRORS_SERVICE_DOWN_DESCRIPTION]:
      'Tenemos algunos problemas. Nos disculpamos por cualquier inconveniente. Por favor, inténtelo de nuevo más tarde.',
    [ERRORS_SERVICE_DOWN_TITLE]: 'El sistema esta caído',
    [ERRORS_SERVICE_DOWN_BUTTON]: 'Intenta otra vez',
  },
  [NOT_FOUND_KEY]: {
    [NOT_FOUND_TITLE]: 'Este link es inválido',
    [NOT_FOUND_DESCRIPTION]:
      'Disculpa, no reconocemos esta dirección. Si la recibiste de un familiar o amigo por favor copia y pega el URL completo y vuélvelo a intentar',
    [NOT_FOUND_BUTTON]: 'Regresar a inicio',
  },
  [ABOUT_KEY]: {
    [ABOUT_TITLE]: 'Envía dinero desde los EEUU a tu familia en {country}',
    [ABOUT_DESCRIPTION]: 'Funciona en 3 simples pasos',
  },
  [DETAILS_KEY]: {
    [DETAILS_ACCOUNT]: 'Detalles de la cuenta',
    [DETAILS_TITLE]: 'Ingresa tus detalles personales',
    [DETAILS_SEND_AMOUNT]: 'Monto a enviar',
  },
  [VERIFICATION_KEY]: {
    [VERIFICATION_ACCOUNT]: 'Detalles de la cuenta',
    [VERIFICATION_TITLE]: 'Introduzca el código de verificación',
    [VERIFICATION_SEND_AMOUNT]: 'Monto a enviar',
  },
  [SUMMARY_KEY]: {
    [SUMMARY_TITLE]: 'Revisar Resumen',
    [SUMMARY_DESCRIPTION]: 'Por favor confirma los detalles antes de enviar',
    [SUMMARY_SENDING]: 'Estas mandando',
    [SUMMARY_EXCHANGE_RATE]: 'Tipo de cambio',
    [SUMMARY_RECIPIENT_GETS]: 'El receptor recibe',
    [SUMMARY_TRANSFER_FEE]: 'Cuota de transferencia',
    [SUMMARY_PAYMENT_DETAILS]: 'Detalles de pago',
    [SUMMARY_PAYMENT_METHOD]: 'Método de pago',
    [SUMMARY_TRANSFER_TOTAL]: 'Total',
    [SUMMARY_BUTTON]: 'Enviar dinero',
    [SUMMARY_TERMS]:
      "Estos servicios son proporcionados por Palla Financial Inc. Al dar click en 'Enviar Dinero' estás confirmando que estás de acuerdo con los detalles de la transferencia presentados en esta pantalla",
    [SUMMARY_DISMISS]: 'OK',
  },
  [TRANSFER_ERROR_KEY]: {
    [TRANSFER_ERROR_SOMETHING_WRONG]: 'Algo salió mal',
    [TRANSFER_ERROR_DESCRIPTION]:
      'No pudimos procesar tu transacción. No te preocupes, tu dinero no se envió. Por favor inténtalo de nuevo',
    [TRANSFER_ERROR_TRY_AGAIN]: 'Inténtalo de nuevo',
  },
  [TRANSFER_SUCCESS_KEY]: {
    [TRANSFER_SUCCESS_SUCCESSFUL]:
      '{amount} {currency} ha sido enviado con éxito {name}!',
    [TRANSFER_SUCCESS_DESCRIPTION]:
      'Los fondos estarán disponibles en un máximo de 30 minutos de esta transacción. También vas a recibir un correo para confirmar la transacción',
    [TRANSFER_SUCCESS_SEND_MORE]: 'Enviar más dinero a {name}',
    [TRANSFER_SUCCESS_VIEW_RECEIPT]: 'Ver recibo',
  },
  [PAYMENT_KEY]: {
    [PAYMENT_ACCOUNT_TITLE]: 'Detalles de la cuenta',
    [PAYMENT_PERSONAL_TITLE]: 'Detalles personales',
    [PAYMENT_TITLE]: 'Pago seguro',
    [PAYMENT_SEND_AMOUNT_TITLE]: 'Monto a enviar',
  },
  [RECEIPT_KEY]: {
    [RECEIPT_FUNDS_AVAILABLE]:
      'Tu dinero estará disponible en un máximo de 30 minutos desde esta transacción',
    [RECEIPT_TRANSFER_DETAILS]: 'Detalles de transferencia',
    [RECEIPT_TRANSFER_DATA]: 'Fecha',
    [RECEIPT_TRANSFER_TRANSACTION]: 'Transacción',
    [RECEIPT_TRANSFER_YOUR_DETAILS]: 'Tus detalles',
    [RECEIPT_OVERVIEW]: 'Resumen de transferencia',
    [RECEIPT_OVERVIEW_AMOUNT]: 'Monto transferido',
    [RECEIPT_OVERVIEW_EXCHANGE_RATE]: 'Tipo de cambio',
    [RECEIPT_OVERVIEW_TRANSFER_FEE]: 'Cuota de transferencia',
    [RECEIPT_OVERVIEW_TRANSFER_TOTAL]: 'Total a transferir',
    [RECEIPT_SEND_TO]: 'Enviar a',
    [RECEIPT_SENT_TO_RECIPIENT_NAME]: 'Nombre del receptor',
    [RECEIPT_SENT_TO_COUNTRY]: 'País',
    [RECEIPT_SENT_TO_TOTAL]: 'Total al receptor',
    [RECEIPT_PAID_FROM]: 'Pagado de',
    [RECEIPT_PAID_FROM_SENDER_NAME]: 'Nombre del enviador',
    [RECEIPT_PAID_FROM_ADDRESS]: 'Dirección',
    [RECEIPT_SENT_MONEY_TO]: 'Enviaste {amount} {currency} a {name} el {date}',
  },
  [SUPPORT_MODAL_KEY]: {
    [SUPPORT_MODAL_HEADER]: '¿No puedes verificar tu teléfono?',
    [SUPPORT_MODAL_TEXT]: `Mándanos un correo a <a id="support" href='mailto:support@palla.app?subject=Support%20Request{sessionId}' target='_blank'>support@palla.app</a> y nos pondremos en contacto contigo lo más pronto posible`,
    [SUPPORT_MODAL_BUTTON]: 'Contactar a Soporte',
  },
  [ABOUT_LIST_KEY]: {
    [ABOUT_LIST_ASK]:
      'Pídele a tu familiar que te comparta su link mágico desde la aplicación de {partner}',
    [ABOUT_LIST_CHOOSE]: 'Escoge cuanto mandar desde tu tarjeta de débito',
    [ABOUT_LIST_INSTANT]: '!El dinero es recibido al instante por tu familiar!',
  },
  [SEND_AMOUNT_KEY]: {
    [SEND_AMOUNT_HOW_MUCH]: 'Cuanto dinero quieres enviar a {name}?',
    [SEND_AMOUNT_TITLE]: 'Monto a enviar',
    [SEND_AMOUNT_AMOUNT_PLACEHOLDER]: '0.00',
    [SEND_AMOUNT_CONTINUE]: 'Continuar',
    [SEND_AMOUNT_RECIPIENT_GETS]: 'Receptor recibe',
    [SEND_AMOUNT_EXCHANGE_RATE]: 'Tipo de Cambio',
    [SEND_AMOUNT_TRANSFER_FEE]: 'Cuota de Transferencia',
    [SEND_AMOUNT_TOTAL]: 'Total',
    [SEND_AMOUNT_CAN_ONLY_TRANSFER_AT_A_TIME]:
      'Sólo puedes transferir hasta {atATimeAmount} {currency} a la vez',
    [SEND_AMOUNT_TRANSFER_FEE_PERCENT_OFF]: '{percentOff}% DESCUENTO',
    [SEND_AMOUNT_VALID_AMOUNT]:
      'Por favor ingresa un número válido arriba {minAmount} {currency}',
    [SEND_AMOUNT_LIMIT_REACHED]:
      'Puedes volver a transferir en {limitReachedDate}',
    [SEND_AMOUNT_FREQUENCY_LIMIT]:
      'Puedes transferir hasta {freqAmount} {currency}',
    [SEND_AMOUNT_AMOUNT_FREQUENCY_LIMIT]:
      'Solo puedes transferir hasta {amtFreqAmount} {currency} hasta el {amtFreqDate}',
    [SEND_AMOUNT_COUNT_FREQUENCY_LIMIT]:
      'Solo puedes transferir hasta {countFreqLimit} a la ves.  Puede transferir de nuevo el {countFreqDate}',
    [SEND_AMOUNT_INVALID_ACTION]: 'Acción de transferencia no válida',
    [SEND_AMOUNT_INVALID_ACCOUNT_TRANSFER]:
      "Acción de transferencia no válida. Por favor contacte a <a href='mailto:support@palla.app?subject=Support%20Request{sessionId}' target='_blank'>support@palla.app</a>",
  },
  [BRAND_HEADER_KEY]: {
    [BRAND_HEADER_TITLE]: 'te invitó a {name}',
    [BRAND_HEADER_SEND_MONEY_INSTANTLY]:
      'Envía dinero al instante a tus familiares y amigos en {country}, las 24 horas, 7 días a la semana',
  },
  [ACCOUNT_DETAILS_KEY]: {
    [ACCOUNT_DETAILS_TITLE]: 'Detalles de la cuenta',
    [ACCOUNT_DETAILS_EMAIL_PLACEHOLDER]: 'adrian.ramirez@palla.app',
    [ACCOUNT_DETAILS_EMAIL_LABEL]: 'Correo eletrónico',
    [ACCOUNT_DETAILS_INT_PLACEHOLDER]: '+1',
    [ACCOUNT_DETAILS_PHONE_LABEL]: 'Teléfono',
    [ACCOUNT_DETAILS_PHONE_PLACEHOLDER]: '(555)555-5555',
    [ACCOUNT_DETAILS_FIRST_NAME_PLACEHOLDER]: 'Adrian',
    [ACCOUNT_DETAILS_FIRST_NAME_LABEL]: 'Nombre',
    [ACCOUNT_DETAILS_LAST_NAME_PLACEHOLDER]: 'Ramirez',
    [ACCOUNT_DETAILS_LAST_NAME_LABEL]: 'Apellido',
    [ACCOUNT_DETAILS_INVALID_EMAIL]: 'Por favor usa una dirección válida',
    [ACCOUNT_DETAILS_INVALID_PHONE]: 'Por favor usa un teléfono válido',
    [ACCOUNT_DETAILS_INVALID_FIRST_NAME]: 'Por favor usa tu nombre legal',
    [ACCOUNT_DETAILS_INVALID_LAST_NAME]: 'Por favor usa tu apellido legal',
    [ACCOUNT_DETAILS_PHONE_TAKEN]: 'Este teléfono ya está registrado',
    [ACCOUNT_DETAILS_TERMS]: `Al continuar usted está de acuerdo en crear una cuenta de Palla como se describe en nuestro <a id="terms" href='https://www.palla.app/terms-and-conditions' target='_blank'>términos y condiciones</a> y nuestra <a id="privacy" href='https://palla.app/privacy-policy' target='_blank'>política de privacidad</a>. Esto asegurará que sus detalles esten seguros y sus futuras transacciones sean más fáciles`,
  },
  [KYC_FAIL_KEY]: {
    [KYC_FAIL_HEADER_TITLE]: 'No pudimos verificar tu identidad',
    [KYC_FAIL_HEADER_DESCRIPTION]:
      'Desafortunadamente no pudimos verificar tu identidad. No nos es posible activar el servicio de envío de fondos internacional hasta que completes este proceso',
    [KYC_FAIL_HEADER_TRY_AGAIN]: 'Por favor inténtalo de nuevo o escribe a ',
    [KYC_FAIL_HEADER_TRY_AGAIN_TWO]:
      ' y nuestro equipo te contestará en 48 horas a más tardar',
  },
  [TRANSACTION_SUMMARY_KEY]: { [TRANSACTION_SUMMARY_TITLE]: 'Resumen' },
  [SECURE_PAYMENT_KEY]: {
    [SECURE_PAYMENT_DEBIT_CARD]: '{name} Tarjeta de débito {lastFour}',
    [SECURE_PAYMENT_TITLE]: 'Pago seguro',
    [SECURE_PAYMENT_DIFFERENT_CARD]: 'Usa otra tarjeta',
    [SECURE_PAYMENT_REVIEW_SUMMARY]: 'Revisa el resumen',
    [SECURE_PAYMENT_DEBIT_ONLY]: 'Solo tarjetas de débito',
  },
  [PERSONAL_DETAILS_KEY]: {
    [PERSONAL_DETAILS_TITLE]: 'Detalles personales',
    [PERSONAL_DETAILS_ADDRESS_ONE_PLACEHOLDER]: '123 Main St',
    [PERSONAL_DETAILS_ADDRESS_ONE_LABEL]: 'Dirección 1',
    [PERSONAL_DETAILS_ADDRESS_TWO_PLACEHOLDER]: 'Apt G',
    [PERSONAL_DETAILS_ADDRESS_TWO_LABEL]: 'Dirección 2',
    [PERSONAL_DETAILS_CITY_PLACEHOLDER]: 'Springfield',
    [PERSONAL_DETAILS_CITY_LABEL]: 'Ciudad',
    [PERSONAL_DETAILS_STATE_PLACEHOLDER]: 'IL',
    [PERSONAL_DETAILS_STATE_LABEL]: 'Estado',
    [PERSONAL_DETAILS_POSTAL_PLACEHOLDER]: '12345',
    [PERSONAL_DETAILS_POSTAL_LABEL]: 'Código postal',
    [PERSONAL_DETAILS_COUNTRY_PLACEHOLDER]: 'United States',
    [PERSONAL_DETAILS_COUNTRY_LABEL]: 'País',
    [PERSONAL_DETAILS_DOB_DAY_PLACEHOLDER]: '31',
    [PERSONAL_DETAILS_DOB_DAY_LABEL]: 'DD',
    [PERSONAL_DETAILS_DOB_MONTH_PLACEHOLDER]: '12',
    [PERSONAL_DETAILS_DOB_MONTH_LABEL]: 'MM',
    [PERSONAL_DETAILS_DOB_YEAR_PLACEHOLDER]: '1999',
    [PERSONAL_DETAILS_DOB_YEAR_LABEL]: 'YYYY',
    [PERSONAL_DETAILS_DOB]: 'Fecha de cumpleaños',
    [PERSONAL_DETAILS_VERIFICATION_HEADER]: 'Informacion personal',
    [PERSONAL_DETAILS_VERIFICATION_SUB_HEADER]:
      'Por seguridad, proporcione la siguiente información.',
    [PERSONAL_DETAILS_VERIFICATION_PHONE_PLACEHOLDER]: '(555)555-5555',
    [PERSONAL_DETAILS_VERIFICATION_PHONE_LABEL]: 'Número de teléfono anterior',
    [PERSONAL_DETAILS_VERIFICATION_INT_PLACEHOLDER]: '+1',
    [PERSONAL_DETAILS_VERIFICATION_DEBIT_CARD_FIRST_SIX_LABEL]:
      'Tarjeta de débito',
    [PERSONAL_DETAILS_VERIFICATION_DEBIT_CARD_FIRST_SIX_PLACEHOLDER]:
      'Primeros 6 dígitos',
    [PERSONAL_DETAILS_VERIFICATION_DEBIT_CARD_LAST_FOUR_LABEL]:
      'Tarjeta de débito',
    [PERSONAL_DETAILS_VERIFICATION_DEBIT_CARD_LAST_FOUR_PLACEHOLDER]:
      'Últimos 4 dígitos',
  },
  [PHONE_VERIFICATION_KEY]: {
    [PHONE_VERIFICATION_SENT_TO_NUMBER]:
      'Ingresa el código de verificación enviado a {maskedNumber}',
    [PHONE_VERIFICATION_RETRY_COUNTDOWN]: 'Por favor espera ({number})',
    [PHONE_VERIFICATION_NEW_CODE]: 'Solicitar un código nuevo',
    [PHONE_VERIFICATION_CANT_ACCESS]: '¿Perdiste el acceso a este teléfono?',
    [PHONE_VERIFICATION_CONTACT_SUPPORT]: 'Contacta a soporte',
    [PHONE_VERIFICATION_UPDATE_PHONE_NUMBER]: 'Actualiza número de teléfono',
  },
  [EMAIL_VERIFICATION_KEY]: {
    [EMAIL_VERIFICATION_SENT_TO_HEADER]: 'Verifique su correo electrónico',
    [EMAIL_VERIFICATION_SUB_HEADER]:
      'Para actualizar su nuevo número de teléfono, le enviamos un código de verificación a {email}. Por favor, introduzca el código de abajo para continuar.',
    [EMAIL_VERIFICATION_NEW_CODE]: 'Enviar de nuevo',
    [EMAIL_VERIFICATION_NEW_CODE_HEADER]:
      'No recibiste ningún correo electrónico?',
  },
  [RECEIPT_FOOTER_KEY]: {
    [RECEIPT_FOOTER_TITLE]: 'Servicios proporcionados por Palla Financial Inc.',
    [RECEIPT_FOOTER_SUB_TITLE]:
      'Receptor puede recibir menos dinero debido a comisiones del banco receptor o impuestos en su país.',
    [RECEIPT_FOOTER_DISPUTE_ONE]:
      'Tiene derecho a disputar errores en su transacción. Si considera que hubo un error en la transacción, por favor contacte a nuestro servicio a clientes hasta 180 días después en ',
    [RECEIPT_FOOTER_DISPUTE_TWO]: ' o llame a ',
    [RECEIPT_FOOTER_CANCEL]:
      'Puede cancelar su transacción hasta 30 minutos después del pago a menos que los fondos hayan sido depositados. También nos pueden contactar para una explicación por escrito de sus derechos.',
    [RECEIPT_FOOTER_CANCEL_SUB]:
      'Para preguntas o quejas de Palla Financial Inc. puede [contactar]:',
    [RECEIPT_FOOTER_PHONE]: '[Tel]: ',
    [RECEIPT_FOOTER_EMAIL]: '[Correo]: ',
    [RECEIPT_FOOTER_FAX]: '[Fax]: ',
    [RECEIPT_FOOTER_CFPB]: 'Consumer Financial Protection Bureau (US)',
  },
  [IDV_DESKTOP_KEY]: {
    [IDV_DESKTOP_PROMPT_TITLE]:
      'Para proceder con tu transferencia tenemos que verificar tu documento',
    [IDV_DESKTOP_PROMPT_TEXT]:
      'A continuar vas a escanear un codigo QR con tu mobil para subir tu documento de identificacion y proceder con tu transferencia. No se necesita ser ciudadano Americano, aceptamos documentos extranjeros',
  },
  [UPDATE_PHONE_KEY]: {
    [UPDATE_PHONE_HEADER]: 'Actualizar número de teléfono',
    [UPDATE_PHONE_PHONE_PLACEHOLDER]: '(555)555-5555',
    [UPDATE_PHONE_PHONE_LABEL]: 'Nuevo número de teléfono ',
    [UPDATE_PHONE_INT_PLACEHOLDER]: '+1',
  },
};
