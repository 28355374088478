import React, { useContext, createContext, useState } from 'react';
import I18nProvider from 'next-translate/I18nProvider';
import useTranslation from 'next-translate/useTranslation';

import localeOptions from '../../../locales';
import usePersistLocaleCookie from '../../hooks/usePersistLocalCookie';
import i18n from '../../../i18n';

const I18nCtx = createContext();

const _validateAcceptLanguage = (language) => {
  if (!language) return null;
  const locale = language.split('-')[0];
  if (
    !Object.keys(localeOptions).includes(locale) ||
    !i18n.locales.includes(locale)
  )
    return null;
  return locale;
};

export function I18nProviderWrapper({ children, acceptLanguage, pallaLang }) {
  const i18n = useTranslation();
  const [locale, changeLocale] = useState(
    pallaLang || _validateAcceptLanguage(acceptLanguage) || i18n.lang
  );
  usePersistLocaleCookie(locale);

  return (
    <I18nCtx.Provider value={{ changeLocale, locale }}>
      <I18nProvider lang={locale} namespaces={{ index: localeOptions[locale] }}>
        {children}
      </I18nProvider>
    </I18nCtx.Provider>
  );
}

export function useChangeLocale() {
  const changeLangContext = useContext(I18nCtx);

  const changeLocale = (v) => {
    if (localeOptions[v] && i18n.locales.includes(v)) {
      changeLangContext.changeLocale(v);
    }
    return i18n.defaultLocale;
  };

  return {
    changeLocale,
    locale: changeLangContext.locale,
    locales: i18n.locales,
  };
}
