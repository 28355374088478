import getConfig from 'next/config';
import { v4 } from 'uuid';

const { publicRuntimeConfig: config } = getConfig();

// Context
const wlCountryAcknowledged = false;
const language = 'en-US'; // TODO -- default language enUS
const userTrackingData = {
  userId: null,
  email: null,
  country: null,
  provider: null,
  scope: null,
  phone: null,
  phoneIntl: null,
  phoneLast4: null,
  existingUser: false,
  preferredLang: 'en',
};
const transfer = {
  inputAmount: null,
  amount: 0.0,
  senderCurrency: config.senderCurrency,
  exchangeCurrency: config.exchangeCurrency,
  exchangeAmount: 0.0,
  exchangeRate: 0.0,
  initialExchangeRate: 0.0,
  transferFee: 0.0,
  initialTransferFee: 0.0,
  maxTransferAmount: config.maxTransferAmount,
  minTransferAmount: config.minTransferAmount,
  totalAmount: 0,
  linkId: '',
  recipientName: '',
  recipientCountry: '',
  exchangeRateExpiration: null,
  country: config.partnerCountry,
  sendMore: false,
  limit: {},
  invalid: false,
  linkUrl: null,
};
const account = {
  email: '',
  phoneIntl: config.senderIntlCode,
  phone: '',
  last4: '',
  firstName: '',
  lastName: '',
  existingUser: false, // TODO -- remove existingUser flag and use 'identity' field in personal
  retry: null,
  status: '',
};
const payment = {
  transactionNumber: '',
  createdAt: '', // Timestamp of payment transfer
  name: '',
  last4: '',
  brand: '',
  paymentMethodId: '',
};
const personal = {
  dob: {
    day: '',
    month: '',
    year: '',
  },
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  zipCode: '',
  country: config.senderCountry,
  identity: {},
};
const identity = {
  verification: {},
  strategy: {},
  verifications: [],
};
const palla = {
  transferId: '',
  token: '',
  scope: [],
  rate: {},
};
const completeTransfer = {
  transferId: '',
  role: '',
  total: 0,
  amount: 0,
  currency: '',
  status: '',
  created: null,
  rate: {
    pair: '',
    rate: 0,
    time: null,
    fee: 0,
  },
  displayRate: {
    pair: '',
    rate: 0,
    time: null,
    fee: 0,
  },
  relationship: {
    relationshipId: '',
    name: {
      first: '',
      last: '',
    },
    country: '',
  },
  paymentMethod: {
    paymentMethodId: '',
    type: '',
    primary: true,
    country: '',
    created: null,
    address: {
      street: '',
      city: '',
      state: '',
      postalCode: '',
      country: '',
    },
    card: {
      brand: '',
      last4: '',
      expMonth: '',
      expYear: '',
    },
  },
  events: [],
};
const paymentCardToken = '';
const challenge = {
  type: '',
  id: '',
  status: '',
  input: [],
};

export default {
  challenge,
  wlCountryAcknowledged,
  sessionId: '',
  userTrackingData,
  language,
  transfer,
  completeTransfer,
  account,
  payment,
  paymentCardToken,
  personal,
  palla,
  identity,
};
