import { createContext, useContext } from 'react';
import { useInterpret, useSelector } from '@xstate/react';
import { useRouter } from 'next/router';
import getConfig from 'next/config';
import { assign } from 'xstate';

import parseJwt from '../utils/parseJwt';
import mapJwtDataToUserData from '../utils/mapJwtDataToUserData';
import track from '../../services/track';
import machine, { EVENTS } from './index';

const { publicRuntimeConfig: config } = getConfig();

const CHECKOUT_APP_STATE = 'CHECKOUT_APP_STATE';

const AppContext = createContext();

const usePersistedState = () => {
  if (typeof window !== 'undefined') {
    const persistedState = window.sessionStorage.getItem(CHECKOUT_APP_STATE);
    if (persistedState) return JSON.parse(persistedState);
  }
  return null;
};
const defaultSelector = (state) => state;
const isValidState = ({ transfer }) =>
  transfer.linkId && transfer.recipientName;
const setPersistedState = (state) =>
  window.sessionStorage.setItem(CHECKOUT_APP_STATE, JSON.stringify(state));
let actions = {};

if (config.trackEvents) {
  actions = {
    resetUserTrackingData: () => track.resetUser(),
    setUserTrackingData: ({ userTrackingData }) =>
      track.setUser(userTrackingData),
    updateUserTrackingData: assign({
      userTrackingData: ({ userTrackingData }, { payload }) => ({
        ...userTrackingData,
        ...(mapJwtDataToUserData(parseJwt(payload?.palla?.token)) || {}),
        email: payload?.account?.email || userTrackingData.email,
        existingUser:
          payload?.account?.existingUser || userTrackingData.existingUser,
        phone: payload?.account?.phone || userTrackingData.phone,
        phoneIntl: payload?.account?.phoneIntl || userTrackingData.phoneIntl,
        phoneLast4: payload?.account?.last4 || userTrackingData.phoneLast4,
        firstName: payload?.account?.firstName || userTrackingData.firstName,
        lastName: payload?.account?.lastName || userTrackingData.lastName,
        preferredLang: payload?.preferredLang || userTrackingData.preferredLang,
      }),
    }),
  };
}

const AppProvider = ({ pageProps, children }) => {
  const router = useRouter();
  const persistedState = usePersistedState();
  const options = { devTools: true };

  // If persisted state exists w/required
  persistedState && isValidState(persistedState.context)
    ? (options.state = persistedState)
    : (options.context = {
        transfer: { ...machine.context.transfer, ...pageProps.transfer },
      });

  const service = useInterpret(
    machine
      .withContext({
        ...machine.context,
        sessionId: pageProps.sessionId,
      })
      .withConfig({ actions }),
    options,
    setPersistedState
  );

  // If router is not ready return null
  if (!router.isReady) return null;

  return <AppContext.Provider value={service}>{children}</AppContext.Provider>;
};

const useApp = (selector = defaultSelector) => {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error('useApp must be used within a AppProvider');
  }
  const appState = useSelector(context, selector);
  const updateIdentity = (identity) =>
    context.send({ type: EVENTS.UPDATE_IDENTITY, payload: { identity } });
  const updatePalla = (palla) =>
    context.send({ type: EVENTS.UPDATE_TOKEN, payload: { palla } });
  const updateAccount = (account) =>
    context.send({
      type: EVENTS.UPDATE_ACCOUNT,
      payload: { account },
    });
  const updatePersonal = (personal) =>
    context.send({
      type: EVENTS.UPDATE_PERSONAL,
      payload: { personal },
    });
  const updateCompleteTransfer = (transfer) => {
    context.send({
      type: EVENTS.UPDATE_COMPLETE_TRANSFER,
      payload: { transfer },
    });
  };
  const clearTransferAmount = () => context.send(EVENTS.CLEAR_TRANSFER_AMOUNT);
  const updatePreferredLang = (lang) =>
    context.send({ type: EVENTS.UPDATE_PREFERRED_LANG, payload: { lang } });
  const acknowledgeWlCountry = () =>
    context.send(EVENTS.ACKNOWLEDGE_WL_COUNTRY);
  const updateChallenge = (challenge) =>
    context.send({
      type: EVENTS.UPDATE_CHALLENGE,
      payload: { challenge },
    });

  return {
    state: appState,
    dispatch: context.send,
    EVENTS,
    updateIdentity,
    updatePalla,
    updateAccount,
    updatePersonal,
    updateChallenge,
    clearTransferAmount,
    updateCompleteTransfer,
    updatePreferredLang,
    acknowledgeWlCountry,
  };
};

export { AppContext, AppProvider, useApp, EVENTS };
