import { assign } from 'xstate';
import context from '../context';

const invalidateAuth = assign({
  palla: () => context.palla,
  account: () => context.account,
  personal: () => context.personal,
});

export default invalidateAuth;
