import { assign } from 'xstate';

const updateAccount = assign({
  account: ({ account }) => {
    return {
      ...account,
      status: 'blocked',
    };
  },
});

export default updateAccount;
