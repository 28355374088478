import posthog, { POSTHOG_TRACK_EVENTS } from './posthog';

const { init, setUser, resetUser } = posthog;

const sendUserInput = (data) =>
  posthog.send(POSTHOG_TRACK_EVENTS.USER_INPUT, data);

const sendPageView = (data) =>
  posthog.send(POSTHOG_TRACK_EVENTS.PAGE_VIEW, data);

const sendAvsFail = (data) =>
  posthog.send(POSTHOG_TRACK_EVENTS.USER_FAIL_AVS, data);

const sendVerificationLevelOneReq = (data) =>
  posthog.send(POSTHOG_TRACK_EVENTS.USER_VERIFICATION_LEVEL_ONE_REQ, data);

const sendVerificationLevelOneFail = (data) =>
  posthog.send(POSTHOG_TRACK_EVENTS.USER_VERIFICATION_LEVEL_ONE_FAIL, data);

const sendVerificationLevelOnePass = (data) =>
  posthog.send(POSTHOG_TRACK_EVENTS.USER_VERIFICATION_LEVEL_ONE_PASS, data);

const sendVerificationLevelTwoReq = (data) =>
  posthog.send(POSTHOG_TRACK_EVENTS.USER_VERIFICATION_LEVEL_TWO_REQ, data);

const sendVerificationLevelTwoCancel = (data) =>
  posthog.send(POSTHOG_TRACK_EVENTS.USER_VERIFICATION_LEVEL_TWO_CANCEL, data);

const sendVerificationLevelTwoFail = (data) =>
  posthog.send(POSTHOG_TRACK_EVENTS.USER_VERIFICATION_LEVEL_TWO_FAIL, data);

const sendVerificationLevelTwoPass = (data) =>
  posthog.send(POSTHOG_TRACK_EVENTS.USER_VERIFICATION_LEVEL_TWO_PASS, data);

const sendInputUpdate = (type, data) => posthog.send(type, data);

const sendService = (type, data) => posthog.send(type, data);

export const INPUT_UPDATE_TYPES = {
  USER_SEND_AMOUNT_UPDATE: POSTHOG_TRACK_EVENTS.USER_SEND_AMOUNT_UPDATE,
  USER_EMAIL_UPDATE: POSTHOG_TRACK_EVENTS.USER_EMAIL_UPDATE,
  USER_PHONE_UPDATE: POSTHOG_TRACK_EVENTS.USER_PHONE_UPDATE,
  USER_FIRST_NAME_UPDATE: POSTHOG_TRACK_EVENTS.USER_FIRST_NAME_UPDATE,
  USER_LAST_NAME_UPDATE: POSTHOG_TRACK_EVENTS.USER_LAST_NAME_UPDATE,
  USER_ADDRESS_ONE_UPDATE: POSTHOG_TRACK_EVENTS.USER_ADDRESS_ONE_UPDATE,
  USER_ADDRESS_TWO_UPDATE: POSTHOG_TRACK_EVENTS.USER_ADDRESS_TWO_UPDATE,
  USER_CITY_UPDATE: POSTHOG_TRACK_EVENTS.USER_CITY_UPDATE,
  USER_STATE_UPDATE: POSTHOG_TRACK_EVENTS.USER_STATE_UPDATE,
  USER_POSTAL_UPDATE: POSTHOG_TRACK_EVENTS.USER_POSTAL_UPDATE,
  USER_DOB_MONTH_UPDATE: POSTHOG_TRACK_EVENTS.USER_DOB_MONTH_UPDATE,
  USER_DOB_DAY_UPDATE: POSTHOG_TRACK_EVENTS.USER_DOB_DAY_UPDATE,
  USER_DOB_YEAR_UPDATE: POSTHOG_TRACK_EVENTS.USER_DOB_YEAR_UPDATE,
};

export const SERVICE_TYPES = {
  SERVICE_GET_FX_RATE: POSTHOG_TRACK_EVENTS.SERVICE_GET_FX_RATE,
  SERVICE_GET_LINK_PROFILE: POSTHOG_TRACK_EVENTS.SERVICE_GET_LINK_PROFILE,
  SERVICE_AUTH_EMAIL: POSTHOG_TRACK_EVENTS.SERVICE_AUTH_EMAIL,
  SERVICE_PHONE_VER_CODE: POSTHOG_TRACK_EVENTS.SERVICE_PHONE_VER_CODE,
  SERVICE_EMAIL_VER_CODE: POSTHOG_TRACK_EVENTS.SERVICE_EMAIL_VER_CODE,
  SERVICE_VERIFY_PHONE: POSTHOG_TRACK_EVENTS.SERVICE_VERIFY_PHONE,
  SERVICE_VERIFY_EMAIL: POSTHOG_TRACK_EVENTS.SERVICE_VERIFY_EMAIL,
  SERVICE_CREATE_IDENTITY: POSTHOG_TRACK_EVENTS.SERVICE_CREATE_IDENTITY,
  SERVICE_CREATE_ACCOUNT_CHALLENGE:
    POSTHOG_TRACK_EVENTS.SERVICE_CREATE_ACCOUNT_CHALLENGE,
  SERVICE_VERIFY_ACCOUNT_CHALLENGE:
    POSTHOG_TRACK_EVENTS.SERVICE_VERIFY_ACCOUNT_CHALLENGE,
  SERVICE_VERIFY_IDENTITY_LEVEL_ONE:
    POSTHOG_TRACK_EVENTS.SERVICE_VERIFY_IDENTITY_LEVEL_ONE,
  SERVICE_VERIFY_IDENTITY_LEVEL_TWO:
    POSTHOG_TRACK_EVENTS.SERVICE_VERIFY_IDENTITY_LEVEL_TWO,
  SERVICE_CREATE_PAYMENT_METHOD:
    POSTHOG_TRACK_EVENTS.SERVICE_CREATE_PAYMENT_METHOD,
  SERVICE_GET_PAYMENT_METHODS: POSTHOG_TRACK_EVENTS.SERVICE_GET_PAYMENT_METHODS,
  SERVICE_CREATE_TRANSFER: POSTHOG_TRACK_EVENTS.SERVICE_CREATE_TRANSFER,
  SERVICE_UPDATE_TRANSFER_EXCHANGE:
    POSTHOG_TRACK_EVENTS.SERVICE_UPDATE_TRANSFER_EXCHANGE,
  SERVICE_GET_ACCOUNT: POSTHOG_TRACK_EVENTS.SERVICE_GET_ACCOUNT,
  SERVICE_CREATE_ACCOUNT: POSTHOG_TRACK_EVENTS.SERVICE_CREATE_ACCOUNT,
  SERVICE_GET_TRANSFER: POSTHOG_TRACK_EVENTS.SERVICE_GET_TRANSFER,
  SERVICE_GET_LIMITS: POSTHOG_TRACK_EVENTS.SERVICE_GET_LIMITS,
  SERVICE_GET_PUBLIC_LIMITS: POSTHOG_TRACK_EVENTS.SERVICE_GET_PUBLIC_LIMITS,
  SERVICE_GET_RELATIONSHIP_BY_LINK_ID:
    POSTHOG_TRACK_EVENTS.SERVICE_GET_RELATIONSHIP_BY_LINK_ID,
  SERVICE_GET_RELATIONSHIP_BY_ID:
    POSTHOG_TRACK_EVENTS.SERVICE_GET_RELATIONSHIP_BY_ID,
  SERVICE_CLEAR_PAYMENT_METHODS:
    POSTHOG_TRACK_EVENTS.SERVICE_CLEAR_PAYMENT_METHODS,
  SERVICE_DELETE_PAYMENT_METHOD:
    POSTHOG_TRACK_EVENTS.SERVICE_DELETE_PAYMENT_METHOD,
  SERVICE_IDENTITY_LEVEL_ONE: POSTHOG_TRACK_EVENTS.SERVICE_IDENTITY_LEVEL_ONE,
  SERVICE_IDENTITY_LEVEL_TWO: POSTHOG_TRACK_EVENTS.SERVICE_IDENTITY_LEVEL_TWO,
  SERVICE_IDENTITY_TOKEN_EXCHANGE:
    POSTHOG_TRACK_EVENTS.SERVICE_IDENTITY_TOKEN_EXCHANGE,
  SERVICE_GET_IDENTITY_VERIFICATIONS:
    POSTHOG_TRACK_EVENTS.SERVICE_GET_IDENTITY_VERIFICATIONS,
};

export default {
  sendUserInput,
  sendPageView,
  sendAvsFail,
  sendVerificationLevelOneReq,
  sendVerificationLevelOneFail,
  sendVerificationLevelOnePass,
  sendVerificationLevelTwoReq,
  sendVerificationLevelTwoCancel,
  sendVerificationLevelTwoFail,
  sendVerificationLevelTwoPass,
  sendInputUpdate,
  sendService,
  init,
  setUser,
  resetUser,
};
