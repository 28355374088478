import { createMachine } from 'xstate';
import { choose } from 'xstate/lib/actions';
import context from './context';
import actions from './actions';

// Events
const EVENTS = {
  UPDATE_AMOUNT: 'UPDATE_AMOUNT',
  UPDATE_ACCOUNT: 'UPDATE_ACCOUNT',
  UPDATE_PERSONAL: 'UPDATE_PERSONAL',
  UPDATE_PAYMENT: 'UPDATE_PAYMENT',
  UPDATE_TOKEN: 'UPDATE_TOKEN',
  UPDATE_CHALLENGE: 'UPDATE_CHALLENGE',
  UPDATE_PALLA_TRANSFER: 'UPDATE_PALLA_TRANSFER',
  UPDATE_PALLA_RATE: 'UPDATE_PALLA_RATE',
  INVALIDATE_AUTH: 'INVALIDATE_AUTH',
  UPDATE_IDENTITY: 'UPDATE_IDENTITY',
  UPDATE_COMPLETE_TRANSFER: 'UPDATE_COMPLETE_TRANSFER',
  ACCOUNT_BLOCKED: 'ACCOUNT_BLOCKED',
  CLEAR_TRANSFER_AMOUNT: 'CLEAR_TRANSFER_AMOUNT',
  UPDATE_PREFERRED_LANG: 'UPDATE_PREFERRED_LANG',
};

// Machine
const machine = createMachine(
  {
    id: 'app',
    context,
    initial: 'idle',
    // entry: ['resetUserTrackingData'],
    entry: choose([
      {
        cond: ({ trackEvents }) => trackEvents,
        actions: 'resetUserTrackingData',
      },
    ]),
    states: {
      idle: {
        on: {
          [EVENTS.UPDATE_PREFERRED_LANG]: {
            actions: 'updatePreferredLang',
          },
          [EVENTS.CLEAR_TRANSFER_AMOUNT]: {
            actions: 'clearTransferAmount',
          },
          [EVENTS.UPDATE_AMOUNT]: {
            actions: ['updateTransfer'],
          },
          [EVENTS.UPDATE_ACCOUNT]: {
            actions: ['updateAccount', 'updatePalla', 'updateUserTrackingData'],
          },
          [EVENTS.UPDATE_PERSONAL]: {
            actions: ['updatePersonal', 'updatePalla'],
          },
          [EVENTS.UPDATE_PAYMENT]: {
            actions: ['updatePayment'],
          },
          [EVENTS.UPDATE_TOKEN]: {
            actions: [
              'updatePalla',
              'updateUserTrackingData',
              'setUserTrackingData',
            ],
          },
          [EVENTS.UPDATE_IDENTITY]: {
            actions: 'updateIdentity',
          },
          [EVENTS.UPDATE_PALLA_TRANSFER]: {
            actions: ['updatePallaTransfer'],
          },
          [EVENTS.UPDATE_PALLA_RATE]: {
            actions: ['updatePallaRate'],
          },
          [EVENTS.INVALIDATE_AUTH]: {
            actions: ['invalidateAuth'],
          },
          [EVENTS.UPDATE_COMPLETE_TRANSFER]: {
            actions: 'updateCompleteTransfer',
          },
          [EVENTS.ACCOUNT_BLOCKED]: { actions: 'setAccountBlocked' },
          [EVENTS.UPDATE_CHALLENGE]: { actions: 'updateChallenge' },
        },
      },
    },
  },
  {
    actions,
  }
);

export default machine;

export { EVENTS };
