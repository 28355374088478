export const COMMON_KEY = 'COMMON';
export const ERRORS_KEY = 'ERRORS';
export const NOT_FOUND_KEY = 'NOT_FOUND';
export const ABOUT_KEY = 'ABOUT';
export const DETAILS_KEY = 'DETAILS';
export const VERIFICATION_KEY = 'VERIFICATION';
export const SUMMARY_KEY = 'SUMMARY';
export const TRANSFER_ERROR_KEY = 'TRANSFER_ERROR';
export const TRANSFER_SUCCESS_KEY = 'TRANSFER_SUCCESS';
export const PAYMENT_KEY = 'PAYMENT';
export const RECEIPT_KEY = 'RECEIPT';
export const SUPPORT_MODAL_KEY = 'SUPPORT_MODAL';
export const ABOUT_LIST_KEY = 'ABOUT_LIST';
export const SEND_AMOUNT_KEY = 'SEND_AMOUNT';
export const BRAND_HEADER_KEY = 'BRAND_HEADER';
export const ACCOUNT_DETAILS_KEY = 'ACCOUNT_DETAILS';
export const KYC_FAIL_KEY = 'KYC_FAIL';
export const KYC_FAIL_HEADER_KEY = 'KYC_FAIL_HEADER';
export const TRANSACTION_SUMMARY_KEY = 'TRANSACTION_SUMMARY';
export const SECURE_PAYMENT_KEY = 'SECURE_PAYMENT';
export const PHONE_VERIFICATION_KEY = 'PHONE_VERIFICATION';
export const EMAIL_VERIFICATION_KEY = 'EMAIL_VERIFICATION';
export const RECEIPT_FOOTER_KEY = 'RECEIPT_FOOTER';
export const IDV_DESKTOP_KEY = 'IDV_DESKTOP';
export const PERSONAL_DETAILS_KEY = 'PERSONAL_DETAILS';
export const UPDATE_PHONE_KEY = 'UPDATE_PHONE';

export default {
  COMMON_EDIT: 'COMMON_EDIT',
  COMMON_BACK: 'COMMON_BACK',
  COMMON_SERVICE_ERROR: 'COMMON_SERVICE_ERROR',
  COMMON_CONTINUE: 'COMMON_CONTINUE',
  COMMON_SEND_MORE_MONEY: 'COMMON_SEND_MORE_MONEY',
  COMMON_RETRY: 'COMMON_RETRY',
  COMMON_ERROR: 'COMMON_ERROR',
  COMMON_COPIED: 'COMMON_COPIED',
  COMMON_SHARE: 'COMMON_SHARE',
  COMMON_UPGRADE_ACCOUNT: 'COMMON_UPGRADE_ACCOUNT',
  COMMON_SEND_MORE_MONEY_CTA: 'COMMON_SEND_MORE_MONEY_CTA',
  COMMON_CANCEL: 'COMMON_CANCEL',
  COMMON_CONTACT_US: 'COMMON_CONTACT_US',
  ERRORS_IDV_UPGRADE_FAIL: 'ERRORS_IDV_UPGRADE_FAIL',
  ERRORS_GENERIC_TITLE: 'ERRORS_GENERIC_TITLE',
  ERRORS_GENERIC_BUTTON: 'ERRORS_GENERIC_BUTTON',
  ERRORS_GENERIC_DESCRIPTION: 'ERRORS_GENERIC_DESCRIPTION',
  ERRORS_INVALID_LINK_TITLE: 'ERRORS_INVALID_LINK_TITLE',
  ERRORS_INVALID_LINK_DESCRIPTION: 'ERRORS_INVALID_LINK_DESCRIPTION',
  ERRORS_INVALID_LINK_BUTTON: 'ERRORS_INVALID_LINK_BUTTON',
  ERRORS_KYC_FAIL_TITLE: 'ERRORS_KYC_FAIL_TITLE',
  ERRORS_KYC_FAIL_DESCRIPTION: 'ERRORS_KYC_FAIL_DESCRIPTION',
  ERRORS_KYC_FAIL_BUTTON: 'ERRORS_KYC_FAIL_BUTTON',
  ERRORS_BLOCKED_TITLE: 'ERRORS_BLOCKED_TITLE',
  ERRORS_BLOCKED_DESCRIPTION: 'ERRORS_BLOCKED_DESCRIPTION',
  ERRORS_BLOCKED_BUTTON: 'ERRORS_BLOCKED_BUTTON',
  ERRORS_TRANSFER_ERROR_REC_TITLE: 'ERRORS_TRANSFER_ERROR_REC_TITLE',
  ERRORS_TRANSFER_ERROR_REC_DESCRIPTION:
    'ERRORS_TRANSFER_ERROR_REC_DESCRIPTION',
  ERRORS_TRANSFER_ERROR_REC_BUTTON: 'ERRORS_TRANSFER_ERROR_REC_BUTTON',
  ERRORS_TRANSFER_ERROR_REC_TRY_AGAIN_BUTTON:
    'ERRORS_TRANSFER_ERROR_REC_TRY_AGAIN_BUTTON',
  ERRORS_TRANSFER_ERROR_TITLE: 'ERRORS_TRANSFER_ERROR_TITLE',
  ERRORS_TRANSFER_ERROR_DESCRIPTION: 'ERRORS_TRANSFER_ERROR_DESCRIPTION',
  ERRORS_TRANSFER_ERROR_BUTTON: 'ERRORS_TRANSFER_ERROR_BUTTON',
  ERRORS_ACCOUNT_TITLE: 'ERRORS_ACCOUNT_TITLE',
  ERRORS_ACCOUNT_INVALID_COUNTRY: 'ERRORS_ACCOUNT_INVALID_COUNTRY',
  ERRORS_ACCOUNT_WHITELIST_COUNTRY_PROCEED:
    'ERRORS_ACCOUNT_WHITELIST_COUNTRY_PROCEED',
  ERRORS_ACCOUNT_US_DEBIT: 'ERRORS_ACCOUNT_US_DEBIT',
  ERRORS_ACCOUNT_US_PHONE: 'ERRORS_ACCOUNT_US_PHONE',
  ERRORS_ACCOUNT_US_ADDRESS: 'ERRORS_ACCOUNT_US_ADDRESS',
  ERRORS_PAYMENT_CREDIT_CARD: 'ERRORS_PAYMENT_CREDIT_CARD',
  ERRORS_PAYMENT_DUPLICATE: 'ERRORS_PAYMENT_DUPLICATE',
  ERRORS_PAYMENT_INVALID: 'ERRORS_PAYMENT_INVALID',
  ERRORS_PAYMENT_INVALID_ADDRESS: 'ERRORS_PAYMENT_INVALID_ADDRESS',
  ERRORS_PAYMENT_INVALID_DETAILS: 'ERRORS_PAYMENT_INVALID_DETAILS',
  ERRORS_PAYMENT_INVALID_USER_DETAILS: 'ERRORS_PAYMENT_INVALID_USER_DETAILS',
  ERRORS_PAYMENT_AMOUNT_LIMIT: 'ERRORS_PAYMENT_AMOUNT_LIMIT',
  ERRORS_PAYMENT_FREQUENCY_LIMIT: 'ERRORS_PAYMENT_FREQUENCY_LIMIT',
  ERRORS_PAYMENT_INVALID_CONFIG: 'ERRORS_PAYMENT_INVALID_CONFIG',
  ERRORS_PAYMENT_INVALID_DETAILS_ADDRESS:
    'ERRORS_PAYMENT_INVALID_DETAILS_ADDRESS',
  ERRORS_PAYMENT_INVALID_PAYMENT_DETAILS:
    'ERRORS_PAYMENT_INVALID_PAYMENT_DETAILS',
  ERRORS_PAYMENT_PAYMENT_TOKEN_EXPIRED: 'ERRORS_PAYMENT_PAYMENT_TOKEN_EXPIRED',
  ERRORS_PAYMENT_INVALID_PAYMENT_METHOD_DETAILS:
    'ERRORS_PAYMENT_INVALID_PAYMENT_METHOD_DETAILS',
  ERRORS_PAYMENT_INVALID_PROVIDER_PAYMENT_CONFIG:
    'ERRORS_PAYMENT_INVALID_PROVIDER_PAYMENT_CONFIG',
  ERRORS_PAYMENT_INVALID_PAYMENT_METHOD_OFAC:
    'ERRORS_PAYMENT_INVALID_PAYMENT_METHOD_OFAC',
  ERRORS_PAYMENT_PAYMENT_METHOD_DECLINED:
    'ERRORS_PAYMENT_PAYMENT_METHOD_DECLINED',
  ERRORS_PAYMENT_PAYMENT_METHOD_CAPABILITY:
    'ERRORS_PAYMENT_PAYMENT_METHOD_CAPABILITY',
  ERRORS_PAYMENT_INVALID_PAYMENT_METHOD_TYPE:
    'ERRORS_PAYMENT_INVALID_PAYMENT_METHOD_TYPE',
  ERRORS_PAYMENT_INVALID_PAYMENT_METHOD_CURRENCY:
    'ERRORS_PAYMENT_INVALID_PAYMENT_METHOD_CURRENCY',
  ERRORS_PAYMENT_PAYMENT_METHOD_PROCESSING_FAILURE:
    'ERRORS_PAYMENT_PAYMENT_METHOD_PROCESSING_FAILURE',
  ERRORS_PERSONAL_DETAILS_ADDRESS_LINE_ONE:
    'ERRORS_PERSONAL_DETAILS_ADDRESS_LINE_ONE',
  ERRORS_PERSONAL_DETAILS_ADDRESS_LINE_TWO:
    'ERRORS_PERSONAL_DETAILS_ADDRESS_LINE_TWO',
  ERRORS_PERSONAL_DETAILS_CITY: 'ERRORS_PERSONAL_DETAILS_CITY',
  ERRORS_PERSONAL_DETAILS_DOB: 'ERRORS_PERSONAL_DETAILS_DOB',
  ERRORS_PERSONAL_DETAILS_STATE: 'ERRORS_PERSONAL_DETAILS_STATE',
  ERRORS_PERSONAL_DETAILS_POSTAL: 'ERRORS_PERSONAL_DETAILS_POSTAL',
  ERRORS_SUMMARY_RATE_CHANGE_HEADER: 'ERRORS_SUMMARY_RATE_CHANGE_HEADER',
  ERRORS_SUMMARY_RATE_CHANGE_TEXT: 'ERRORS_SUMMARY_RATE_CHANGE_TEXT',
  ERRORS_SUMMARY_AMOUNT_LIMIT_REACHED_HEADER:
    'ERRORS_SUMMARY_AMOUNT_LIMIT_REACHED_HEADER',
  ERRORS_SUMMARY_AMOUNT_LIMIT_REACHED_TEXT:
    'ERRORS_SUMMARY_AMOUNT_LIMIT_REACHED_TEXT',
  ERRORS_SUMMARY_AMOUNT_LIMIT_HEADER: 'ERRORS_SUMMARY_AMOUNT_LIMIT_HEADER',
  ERRORS_SUMMARY_AMOUNT_LIMIT_TEXT: 'ERRORS_SUMMARY_AMOUNT_LIMIT_TEXT',
  ERRORS_SUMMARY_AMOUNT_LIMIT_TEXT_MAX: 'ERRORS_SUMMARY_AMOUNT_LIMIT_TEXT_MAX',
  ERRORS_SUMMARY_FREQUENCY_LIMIT_HEADER:
    'ERRORS_SUMMARY_FREQUENCY_LIMIT_HEADER',
  ERRORS_SUMMARY_FREQUENCY_LIMIT_TEXT: 'ERRORS_SUMMARY_FREQUENCY_LIMIT_TEXT',
  ERRORS_SERVICE_DOWN_DESCRIPTION: 'ERRORS_SERVICE_DOWN_DESCRIPTION',
  ERRORS_SERVICE_DOWN_TITLE: 'ERRORS_SERVICE_DOWN_TITLE',
  ERRORS_SERVICE_DOWN_BUTTON: 'ERRORS_SERVICE_DOWN_BUTTON',
  ERRORS_PHONE_VERIFICATION_INVALID_OTP:
    'ERRORS_PHONE_VERIFICATION_INVALID_OTP',
  NOT_FOUND_TITLE: 'NOT_FOUND_TITLE',
  NOT_FOUND_DESCRIPTION: 'NOT_FOUND_DESCRIPTION',
  NOT_FOUND_BUTTON: 'NOT_FOUND_BUTTON',
  ABOUT_TITLE: 'ABOUT_TITLE',
  ABOUT_DESCRIPTION: 'ABOUT_DESCRIPTION',
  DETAILS_ACCOUNT: 'DETAILS_ACCOUNT',
  DETAILS_TITLE: 'DETAILS_TITLE',
  DETAILS_SEND_AMOUNT: 'DETAILS_SEND_AMOUNT',
  VERIFICATION_ACCOUNT: 'VERIFICATION_ACCOUNT',
  VERIFICATION_TITLE: 'VERIFICATION_TITLE',
  VERIFICATION_SEND_AMOUNT: 'VERIFICATION_SEND_AMOUNT',
  SUMMARY_TITLE: 'SUMMARY_TITLE',
  SUMMARY_DESCRIPTION: 'SUMMARY_DESCRIPTION',
  SUMMARY_SENDING: 'SUMMARY_SENDING',
  SUMMARY_EXCHANGE_RATE: 'SUMMARY_EXCHANGE_RATE',
  SUMMARY_RECIPIENT_GETS: 'SUMMARY_RECIPIENT_GETS',
  SUMMARY_TRANSFER_FEE: 'SUMMARY_TRANSFER_FEE',
  SUMMARY_PAYMENT_DETAILS: 'SUMMARY_PAYMENT_DETAILS',
  SUMMARY_PAYMENT_METHOD: 'SUMMARY_PAYMENT_METHOD',
  SUMMARY_TRANSFER_TOTAL: 'SUMMARY_TRANSFER_TOTAL',
  SUMMARY_BUTTON: 'SUMMARY_BUTTON',
  SUMMARY_TERMS: 'SUMMARY_TERMS',
  SUMMARY_DISMISS: 'SUMMARY_DISMISS',
  TRANSFER_ERROR_SOMETHING_WRONG: 'TRANSFER_ERROR_SOMETHING_WRONG',
  TRANSFER_ERROR_DESCRIPTION: 'TRANSFER_ERROR_DESCRIPTION',
  TRANSFER_ERROR_TRY_AGAIN: 'TRANSFER_ERROR_TRY_AGAIN',
  TRANSFER_SUCCESS_SUCCESSFUL: 'TRANSFER_SUCCESS_SUCCESSFUL',
  TRANSFER_SUCCESS_DESCRIPTION: 'TRANSFER_SUCCESS_DESCRIPTION',
  TRANSFER_SUCCESS_SEND_MORE: 'TRANSFER_SUCCESS_SEND_MORE',
  TRANSFER_SUCCESS_VIEW_RECEIPT: 'TRANSFER_SUCCESS_VIEW_RECEIPT',
  PAYMENT_ACCOUNT_TITLE: 'PAYMENT_ACCOUNT_TITLE',
  PAYMENT_PERSONAL_TITLE: 'PAYMENT_PERSONAL_TITLE',
  PAYMENT_TITLE: 'PAYMENT_TITLE',
  PAYMENT_SEND_AMOUNT_TITLE: 'PAYMENT_SEND_AMOUNT_TITLE',
  RECEIPT_FUNDS_AVAILABLE: 'RECEIPT_FUNDS_AVAILABLE',
  RECEIPT_TRANSFER_DETAILS: 'RECEIPT_TRANSFER_DETAILS',
  RECEIPT_TRANSFER_DATA: 'RECEIPT_TRANSFER_DATA',
  RECEIPT_TRANSFER_TRANSACTION: 'RECEIPT_TRANSFER_TRANSACTION',
  RECEIPT_TRANSFER_YOUR_DETAILS: 'RECEIPT_TRANSFER_YOUR_DETAILS',
  RECEIPT_OVERVIEW: 'RECEIPT_OVERVIEW',
  RECEIPT_OVERVIEW_AMOUNT: 'RECEIPT_OVERVIEW_AMOUNT',
  RECEIPT_OVERVIEW_EXCHANGE_RATE: 'RECEIPT_OVERVIEW_EXCHANGE_RATE',
  RECEIPT_OVERVIEW_TRANSFER_FEE: 'RECEIPT_OVERVIEW_TRANSFER_FEE',
  RECEIPT_OVERVIEW_TRANSFER_TOTAL: 'RECEIPT_OVERVIEW_TRANSFER_TOTAL',
  RECEIPT_SEND_TO: 'RECEIPT_SEND_TO',
  RECEIPT_SENT_TO_RECIPIENT_NAME: 'RECEIPT_SENT_TO_RECIPIENT_NAME',
  RECEIPT_SENT_TO_COUNTRY: 'RECEIPT_SENT_TO_COUNTRY',
  RECEIPT_SENT_TO_TOTAL: 'RECEIPT_SENT_TO_TOTAL',
  RECEIPT_PAID_FROM: 'RECEIPT_PAID_FROM',
  RECEIPT_PAID_FROM_SENDER_NAME: 'RECEIPT_PAID_FROM_SENDER_NAME',
  RECEIPT_PAID_FROM_ADDRESS: 'RECEIPT_PAID_FROM_ADDRESS',
  RECEIPT_SENT_MONEY_TO: 'RECEIPT_SENT_MONEY_TO',
  SUPPORT_MODAL_HEADER: 'SUPPORT_MODAL_HEADER',
  SUPPORT_MODAL_TEXT: 'SUPPORT_MODAL_TEXT',
  SUPPORT_MODAL_BUTTON: 'SUPPORT_MODAL_BUTTON',
  ABOUT_LIST_ASK: 'ABOUT_LIST_ASK',
  ABOUT_LIST_CHOOSE: 'ABOUT_LIST_CHOOSE',
  ABOUT_LIST_INSTANT: 'ABOUT_LIST_INSTANT',
  SEND_AMOUNT_TITLE: 'SEND_AMOUNT_TITLE',
  SEND_AMOUNT_AMOUNT: 'SEND_AMOUNT_AMOUNT',
  SEND_AMOUNT_RECIPIENT_GETS: 'SEND_AMOUNT_RECIPIENT_GETS',
  SEND_AMOUNT_TRANSFER_FEE: 'SEND_AMOUNT_TRANSFER_FEE',
  SEND_AMOUNT_TOTAL: 'SEND_AMOUNT_TOTAL',
  SEND_AMOUNT_EXCHANGE_RATE: 'SEND_AMOUNT_EXCHANGE_RATE',
  SEND_AMOUNT_HOW_MUCH: 'SEND_AMOUNT_HOW_MUCH',
  SEND_AMOUNT_AMOUNT_PLACEHOLDER: 'SEND_AMOUNT_AMOUNT_PLACEHOLDER',
  SEND_AMOUNT_CONTINUE: 'SEND_AMOUNT_CONTINUE',
  SEND_AMOUNT_CAN_ONLY_TRANSFER_AT_A_TIME:
    'SEND_AMOUNT_CAN_ONLY_TRANSFER_AT_A_TIME',
  SEND_AMOUNT_TRANSFER_FEE_PERCENT_OFF: 'SEND_AMOUNT_TRANSFER_FEE_PERCENT_OFF',
  SEND_AMOUNT_VALID_AMOUNT: 'SEND_AMOUNT_VALID_AMOUNT',
  SEND_AMOUNT_LIMIT_REACHED: 'SEND_AMOUNT_LIMIT_REACHED',
  SEND_AMOUNT_FREQUENCY_LIMIT: 'SEND_AMOUNT_FREQUENCY_LIMIT',
  SEND_AMOUNT_AMOUNT_FREQUENCY_LIMIT: 'SEND_AMOUNT_AMOUNT_FREQUENCY_LIMIT',
  SEND_AMOUNT_COUNT_FREQUENCY_LIMIT: 'SEND_AMOUNT_COUNT_FREQUENCY_LIMIT',
  SEND_AMOUNT_INVALID_ACTION: 'SEND_AMOUNT_INVALID_ACTION',
  SEND_AMOUNT_INVALID_ACCOUNT_TRANSFER: 'SEND_AMOUNT_INVALID_ACCOUNT_TRANSFER',
  BRAND_HEADER_TITLE: 'BRAND_HEADER_TITLE',
  BRAND_HEADER_SEND_MONEY_INSTANTLY: 'BRAND_HEADER_SEND_MONEY_INSTANTLY',
  ACCOUNT_DETAILS_TITLE: 'ACCOUNT_DETAILS_TITLE',
  ACCOUNT_DETAILS_EMAIL_PLACEHOLDER: 'ACCOUNT_DETAILS_EMAIL_PLACEHOLDER',
  ACCOUNT_DETAILS_EMAIL_LABEL: 'ACCOUNT_DETAILS_EMAIL_LABEL',
  ACCOUNT_DETAILS_INT_PLACEHOLDER: 'ACCOUNT_DETAILS_INT_PLACEHOLDER',
  ACCOUNT_DETAILS_PHONE_LABEL: 'ACCOUNT_DETAILS_PHONE_LABEL',
  ACCOUNT_DETAILS_PHONE_PLACEHOLDER: 'ACCOUNT_DETAILS_PHONE_PLACEHOLDER',
  ACCOUNT_DETAILS_FIRST_NAME_PLACEHOLDER:
    'ACCOUNT_DETAILS_FIRST_NAME_PLACEHOLDER',
  ACCOUNT_DETAILS_FIRST_NAME_LABEL: 'ACCOUNT_DETAILS_FIRST_NAME_LABEL',
  ACCOUNT_DETAILS_LAST_NAME_PLACEHOLDER:
    'ACCOUNT_DETAILS_LAST_NAME_PLACEHOLDER',
  ACCOUNT_DETAILS_LAST_NAME_LABEL: 'ACCOUNT_DETAILS_LAST_NAME_LABEL',
  ACCOUNT_DETAILS_INVALID_EMAIL: 'ACCOUNT_DETAILS_INVALID_EMAIL',
  ACCOUNT_DETAILS_INVALID_PHONE: 'ACCOUNT_DETAILS_INVALID_PHONE',
  ACCOUNT_DETAILS_INVALID_FIRST_NAME: 'ACCOUNT_DETAILS_INVALID_FIRST_NAME',
  ACCOUNT_DETAILS_INVALID_LAST_NAME: 'ACCOUNT_DETAILS_INVALID_LAST_NAME',
  ACCOUNT_DETAILS_PHONE_TAKEN: 'ACCOUNT_DETAILS_PHONE_TAKEN',
  ACCOUNT_DETAILS_TERMS: 'ACCOUNT_DETAILS_TERMS',
  KYC_FAIL_HEADER_TITLE: 'KYC_FAIL_HEADER_TITLE',
  KYC_FAIL_HEADER_DESCRIPTION: 'KYC_FAIL_HEADER_DESCRIPTION',
  KYC_FAIL_HEADER_TRY_AGAIN: 'KYC_FAIL_HEADER_TRY_AGAIN',
  KYC_FAIL_HEADER_TRY_AGAIN_TWO: 'KYC_FAIL_HEADER_TRY_AGAIN_TWO',
  TRANSACTION_SUMMARY_TITLE: 'TRANSACTION_SUMMARY_TITLE',
  SECURE_PAYMENT_DEBIT_CARD: 'SECURE_PAYMENT_DEBIT_CARD',
  SECURE_PAYMENT_TITLE: 'SECURE_PAYMENT_TITLE',
  SECURE_PAYMENT_DIFFERENT_CARD: 'SECURE_PAYMENT_DIFFERENT_CARD',
  SECURE_PAYMENT_REVIEW_SUMMARY: 'SECURE_PAYMENT_REVIEW_SUMMARY',
  SECURE_PAYMENT_DEBIT_ONLY: 'SECURE_PAYMENT_DEBIT_ONLY',
  PERSONAL_DETAILS_TITLE: 'PERSONAL_DETAILS_TITLE',
  PERSONAL_DETAILS_ADDRESS_ONE_PLACEHOLDER:
    'PERSONAL_DETAILS_ADDRESS_ONE_PLACEHOLDER',
  PERSONAL_DETAILS_ADDRESS_ONE_LABEL: 'PERSONAL_DETAILS_ADDRESS_ONE_LABEL',
  PERSONAL_DETAILS_ADDRESS_TWO_PLACEHOLDER:
    'PERSONAL_DETAILS_ADDRESS_TWO_PLACEHOLDER',
  PERSONAL_DETAILS_ADDRESS_TWO_LABEL: 'PERSONAL_DETAILS_ADDRESS_TWO_LABEL',
  PERSONAL_DETAILS_CITY_PLACEHOLDER: 'PERSONAL_DETAILS_CITY_PLACEHOLDER',
  PERSONAL_DETAILS_CITY_LABEL: 'PERSONAL_DETAILS_CITY_LABEL',
  PERSONAL_DETAILS_STATE_PLACEHOLDER: 'PERSONAL_DETAILS_STATE_PLACEHOLDER',
  PERSONAL_DETAILS_STATE_LABEL: 'PERSONAL_DETAILS_STATE_LABEL',
  PERSONAL_DETAILS_POSTAL_PLACEHOLDER: 'PERSONAL_DETAILS_POSTAL_PLACEHOLDER',
  PERSONAL_DETAILS_POSTAL_LABEL: 'PERSONAL_DETAILS_POSTAL_LABEL',
  PERSONAL_DETAILS_COUNTRY_PLACEHOLDER: 'PERSONAL_DETAILS_COUNTRY_PLACEHOLDER',
  PERSONAL_DETAILS_COUNTRY_LABEL: 'PERSONAL_DETAILS_COUNTRY_LABEL',
  PERSONAL_DETAILS_DOB_DAY_PLACEHOLDER: 'PERSONAL_DETAILS_DOB_DAY_PLACEHOLDER',
  PERSONAL_DETAILS_DOB_DAY_LABEL: 'PERSONAL_DETAILS_DOB_DAY_LABEL',
  PERSONAL_DETAILS_DOB_MONTH_PLACEHOLDER:
    'PERSONAL_DETAILS_DOB_MONTH_PLACEHOLDER',
  PERSONAL_DETAILS_DOB_MONTH_LABEL: 'PERSONAL_DETAILS_DOB_MONTH_LABEL',
  PERSONAL_DETAILS_DOB_YEAR_PLACEHOLDER:
    'PERSONAL_DETAILS_DOB_YEAR_PLACEHOLDER',
  PERSONAL_DETAILS_DOB_YEAR_LABEL: 'PERSONAL_DETAILS_DOB_YEAR_LABEL',
  PERSONAL_DETAILS_DOB: 'PERSONAL_DETAILS_DOB',
  PHONE_VERIFICATION_SENT_TO_NUMBER: 'PHONE_VERIFICATION_SENT_TO_NUMBER',
  PHONE_VERIFICATION_RETRY_COUNTDOWN: 'PHONE_VERIFICATION_RETRY_COUNTDOWN',
  PHONE_VERIFICATION_NEW_CODE: 'PHONE_VERIFICATION_NEW_CODE',
  PHONE_VERIFICATION_CANT_ACCESS: 'PHONE_VERIFICATION_CANT_ACCESS',
  PHONE_VERIFICATION_CONTACT_SUPPORT: 'PHONE_VERIFICATION_CONTACT_SUPPORT',
  PHONE_VERIFICATION_UPDATE_PHONE_NUMBER:
    'PHONE_VERIFICATION_UPDATE_PHONE_NUMBER',
  EMAIL_VERIFICATION_SENT_TO_HEADER: 'EMAIL_VERIFICATION_SENT_TO_HEADER',
  EMAIL_VERIFICATION_SUB_HEADER: 'EMAIL_VERIFICATION_SUB_HEADER',
  EMAIL_VERIFICATION_NEW_CODE: 'EMAIL_VERIFICATION_NEW_CODE',
  EMAIL_VERIFICATION_NEW_CODE_HEADER: 'EMAIL_VERIFICATION_NEW_CODE_HEADER',
  RECEIPT_FOOTER_TITLE: 'RECEIPT_FOOTER_TITLE',
  RECEIPT_FOOTER_SUB_TITLE: 'RECEIPT_FOOTER_SUB_TITLE',
  RECEIPT_FOOTER_DISPUTE_ONE: 'RECEIPT_FOOTER_DISPUTE_ONE',
  RECEIPT_FOOTER_DISPUTE_TWO: 'RECEIPT_FOOTER_DISPUTE_TWO',
  RECEIPT_FOOTER_CANCEL: 'RECEIPT_FOOTER_CANCEL',
  RECEIPT_FOOTER_CANCEL_SUB: 'RECEIPT_FOOTER_CANCEL_SUB',
  RECEIPT_FOOTER_PHONE: 'RECEIPT_FOOTER_PHONE',
  RECEIPT_FOOTER_EMAIL: 'RECEIPT_FOOTER_EMAIL',
  RECEIPT_FOOTER_FAX: 'RECEIPT_FOOTER_FAX',
  RECEIPT_FOOTER_CFPB: 'RECEIPT_FOOTER_CFPB',
  IDV_DESKTOP_PROMPT_TITLE: 'IDV_DESKTOP_PROMPT_TITLE',
  IDV_DESKTOP_PROMPT_TEXT: 'IDV_DESKTOP_PROMPT_TEXT',
  PERSONAL_DETAILS_VERIFICATION_HEADER: 'PERSONAL_DETAILS_VERIFICATION_HEADER',
  PERSONAL_DETAILS_VERIFICATION_SUB_HEADER:
    'PERSONAL_DETAILS_VERIFICATION_SUB_HEADER',
  PERSONAL_DETAILS_VERIFICATION_PHONE_PLACEHOLDER:
    'PERSONAL_DETAILS_VERIFICATION_PHONE_PLACEHOLDER',
  PERSONAL_DETAILS_VERIFICATION_PHONE_LABEL:
    'PERSONAL_DETAILS_VERIFICATION_PHONE_LABEL',
  PERSONAL_DETAILS_VERIFICATION_INT_PLACEHOLDER:
    'PERSONAL_DETAILS_VERIFICATION_INT_PLACEHOLDER',
  PERSONAL_DETAILS_VERIFICATION_DEBIT_CARD_FIRST_SIX_LABEL:
    'PERSONAL_DETAILS_VERIFICATION_DEBIT_CARD_FIRST_SIX_LABEL',
  PERSONAL_DETAILS_VERIFICATION_DEBIT_CARD_FIRST_SIX_PLACEHOLDER:
    'PERSONAL_DETAILS_VERIFICATION_DEBIT_CARD_FIRST_SIX_PLACEHOLDER',
  PERSONAL_DETAILS_VERIFICATION_DEBIT_CARD_LAST_FOUR_LABEL:
    'PERSONAL_DETAILS_VERIFICATION_DEBIT_CARD_LAST_FOUR_LABEL',
  PERSONAL_DETAILS_VERIFICATION_DEBIT_CARD_LAST_FOUR_PLACEHOLDER:
    'PERSONAL_DETAILS_VERIFICATION_DEBIT_CARD_LAST_FOUR_PLACEHOLDER',
  ERRORS_PERSONAL_DETAILS_VERIFICATION_DEBIT_LAST_4:
    'ERRORS_PERSONAL_DETAILS_VERIFICATION_DEBIT_LAST_4',
  ERRORS_PERSONAL_DETAILS_VERIFICATION_DEBIT_FIRST_6:
    'ERRORS_PERSONAL_DETAILS_VERIFICATION_DEBIT_FIRST_6',
  ERRORS_PERSONAL_DETAILS_VERIFICATION_ATTEMPT:
    'ERRORS_PERSONAL_DETAILS_VERIFICATION_ATTEMPT',
  ERRORS_PHONE_CHANGE_DESCRIPTION: 'ERRORS_PHONE_CHANGE_DESCRIPTION',
  ERRORS_PHONE_CHANGE_TITLE: 'ERRORS_PHONE_CHANGE_TITLE',
  ERRORS_PHONE_CHANGE_BUTTON: 'ERRORS_PHONE_CHANGE_BUTTON',
  UPDATE_PHONE_HEADER: 'UPDATE_PHONE_HEADER',
  UPDATE_PHONE_PHONE_PLACEHOLDER: 'UPDATE_PHONE_PHONE_PLACEHOLDER',
  UPDATE_PHONE_PHONE_LABEL: 'UPDATE_PHONE_PHONE_LABEL',
  UPDATE_PHONE_INT_PLACEHOLDER: 'UPDATE_PHONE_INT_PLACEHOLDER',
};
