import { useEffect } from 'react';
import getConfig from 'next/config';
import appWithI18n from 'next-translate/appWithI18n';
import { useRouter } from 'next/router';
import { inspect } from '@xstate/inspect';
import { AppProvider } from '../src/contexts/appContext/AppContext';
import track from '../src/services/track';

import i18nConfig from '../i18n';

import '../theme/theme.scss';
import { I18nProviderWrapper } from '../src/contexts/i18n';

const { publicRuntimeConfig: config } = getConfig();

if (typeof window !== 'undefined') {
  // init xstate debugger
  if (config.xdebugger) {
    inspect({ iframe: false });
  }

  // TODO -- Move tracking to its own machine if complexity increases
  // init tracking service
  if (config.trackEvents) {
    track.init();
  }
}

const useInterceptNextDataHref = ({ router, namespace }) => {
  useEffect(() => {
    if (router.pageLoader?.getDataHref) {
      const originalGetDataHref = router.pageLoader.getDataHref;
      router.pageLoader.getDataHref = function (...args) {
        const r = originalGetDataHref.call(router.pageLoader, ...args);
        return r && r.startsWith('/_next/data') ? `/${namespace}${r}` : r;
      };
    }
  }, [router, namespace]);
};

const App = ({ Component, pageProps }) => {
  const router = useRouter();
  useEffect(() => {
    track.sendPageView({ path: router.pathname, query: router.query });
  }, [router.pathname]);

  return <Component {...pageProps} />;
};

const MyApp = ({ pageProps, router, ...props }) => {
  useInterceptNextDataHref({
    router,
    namespace: config.appName ? `${config.appName}/data` : '',
  });
  return (
    <AppProvider pageProps={pageProps}>
      <I18nProviderWrapper
        acceptLanguage={pageProps.acceptLanguage}
        pallaLang={pageProps.pallaLang}
      >
        <App {...{ ...props, router, pageProps }} />
      </I18nProviderWrapper>
    </AppProvider>
  );
};

export default appWithI18n(MyApp, { ...i18nConfig, skipInitialProps: true });
