import { assign } from 'xstate';

const updatePersonal = assign({
  personal: ({ personal }, { payload }) => {
    return {
      ...personal,
      ...payload.personal,
    };
  },
});

export default updatePersonal;
